.sessionthumb {
    display: grid;
    grid-template-columns: repeat(4,1fr);
    gap: 2rem 4rem;

    &__session {

        img {
            border-radius: 1rem;
            transition: transform 0.4s ease;
        }

        .h4 {
            padding-top: 0.4em;
            font-size: 2.4rem;
            font-weight: 500;
            @include ls-50;

            @media #{$media-mobile} { 
                font-size: 2rem;
                @include ls-50;
            }
        }

        // ui
        
        &:hover, &:active, &:focus {
            img {
                transform: scale(1.04);
            }
        }
    }

    @media #{$media-tablet} { 
        grid-template-columns: repeat(3,1fr);
    }

    @media #{$media-mobile} { 
        grid-template-columns: repeat(2,1fr);
    }

    @media #{$media-mobile-sm} { 
        grid-template-columns: 1fr;
    }
}