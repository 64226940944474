.svg-to-orange {
    position: relative;
    display: block;

    &::after {
        content:'';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: $orange;
        opacity: 0;
        mix-blend-mode: multiply;
        transition: opacity 0.3s ease;
    }
}

.svg-to-orange:hover, 
.svg-to-orange:active, 
.svg-to-orange:focus {
    &::after {
        opacity: 1;
    }
}
