.template-post-event {

    // title
    .s0 {
        
    }

    // featured sessions - top
    .s1 {
        position: relative;
        width: 100%;

        &__postcard {
            position: absolute;
            opacity: 0;
            top: 0;
            left: 0;
            width: 100%;
            background-color: white;

            display: grid;
            grid-template-columns: 50% 50%;
            gap: 1.5rem;

            &__image {
                min-height: 50vh!important;

                border-radius: 1rem;
                transition: transform 0.4s ease;

                &:hover {
                    transform: scale(1.01);
                }
            }

            &__text {
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                &__top {
                    .h5 {
                        text-transform: uppercase;
                        font-size: 1.8rem;
                        letter-spacing: 0;
                        padding-bottom: 0.4em;
                        @include ls;

                        @media #{$media-mobile} { 
                            font-size: 1.5rem;
                            @include ls-50;
                        }
                    }
                    .h3 {
                        font-size: 3.6rem;
                        font-weight: 500;
                        @include ls-50;
                        padding-bottom: 0;

                        @media #{$media-mobile} { 
                            font-size: 3rem;
                            @include ls;
                        }
                    }
                    .description {
                        font-size: 1.8rem;
                        @include ls-50;

                        margin-top: 1.4rem;
                        padding-top: 1.6rem;
                        border-top: 1px solid black;
                        margin-bottom: 1em;

                        @media #{$media-mobile} { 
                            font-size: 1.5rem;
                            @include ls-50;
                        }
                    }
                }
                &__bottom {
                    .h6 {
                        font-size: 1.8rem;
                        line-height: 1.2em;
                        text-decoration: underline;

                        @media #{$media-mobile} { 
                            font-size: 1.5rem;
                        }
                    }
                    .speakers {
                        column-count: 2;

                        .speakers__name {
                            font-size: 1.8rem;
                            line-height: 1.2em;

                            @media #{$media-mobile} { 
                                font-size: 1.5rem;
                            }
                        }
                    }
                }
            }

            // ux
            transition: opacity 0.7s ease;
            pointer-events: none;
            &.active{
                pointer-events: all;
                opacity: 1;
            }

        }

        &.unloaded {

            .s1__postcard {

                left: -21%;
                transition: left 2s cubic-bezier(0.075, 0.82, 0.165, 1), opacity 1.5s ease;
                // transition: left 1.4s ease-out, opacity 1.5s ease;

                &.active{
                    left: 0;
                }

            }
        }
    }
    // featured sessions - slider
    .s1bis {
        padding-top: 0;
        z-index: 10;
        position: relative;
        overflow: hidden;
        
        &__nav {

            display: flex;
            align-items: center;
            justify-content: end;

            // margin-top: $pad-v;
            padding: 2rem 0;
            border-top: 1px solid black;

            svg {
                cursor: pointer;
                height: 2.5rem;
                position: static;
                margin: 0;

                &:hover, &:active, &:focus {
                    path {
                        stroke: $orange;
                    }
                }
            }
        }

        &__slide {
            width: unquote("max(40vw, 50rem)");

            display: grid;
            grid-template-columns: 45% 1fr;

            -webkit-user-select: none;  /* Chrome all / Safari all */
            -moz-user-select: none;     /* Firefox all */
            -ms-user-select: none;      /* IE 10+ */
            user-select: none;          /* Likely future */ 

            cursor: pointer;

            &__image {
                height: unquote("max(13vw, 15rem)");

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;

                    border-radius: 1rem;

                    transition: transform 0.4s ease;
                }

                &:hover img {
                    transform: scale(1.04);
                }

            }

            &__text {

                padding: 0 1.5rem;
                border-right: 1px solid black;
                margin-right: 3rem;

                display: flex;
                flex-direction: column;
                justify-content: space-between;

                height: 100%;

                .h4 {
                    font-size: 2.4rem;
                    @include ls-50;

                    @media #{$media-mobile} { 
                        font-size: 2rem;
                        @include ls-50;
                    }
                }

                a {
                    text-decoration: underline;
                }
            }

            // &:first-of-type {
            //     margin-left: $pad-h;
            // }

            // &.active {
            //     .s1bis__slide__text {
            //         color: $orange;
            //     }
            // }
        }

        &__border {
            padding-bottom: 5rem;
            border-bottom: 1px solid black;
        }

        .swiper {
            height: fit-content;
            overflow: visible;

            .bg {
                position: absolute;
                right: -5rem;
                top: -5rem;
                width: 5rem;
                bottom: -5rem;
                // background: linear-gradient(to left, white 0 , transparent);
                background-color: white;
                z-index: 10;
                pointer-events: none;
            }

            .bg2 {
                position: absolute;
                left: -5rem;
                top: -5rem;
                width: 4rem;
                bottom: -5rem;
                // background: linear-gradient(to left, white 0 , transparent);
                background-color: white;
                z-index: 10;
                pointer-events: none;
            }
        }
    }

    .s2 {
        min-height: 30vw;

        // - - - - - - - - - - - - - - - 
        // forms @ components/forms.scss
        // - - - - - - - - - - - - - - - 

        .sessionthumb {
            margin-bottom: 2rem;

            &__session {
                opacity: 0;
                display: none;
                transition: opacity 0.7s ease;
            }
        }
    }

    
    // gallery
    .s3 {
        background-color: black;
        color: white;

        &__top {
            .h3 {
                font-size: 4rem;
                @include ls-50;
                padding-bottom: 0.2em;

                @media #{$media-mobile} { 
                    font-size: 3.2rem;
                    @include ls-50;
                }
            }
            .text {
                max-width: 39rem;
                @include ls-50;
            }

            margin-bottom: 4rem;
        }

        &__gallery {
            display: grid;
            grid-template-columns: 1fr 1fr;

            &__pic {
                display: none;
                opacity: 0;
                transition: opacity 0.7s ease;
                width: 100%;
                height: unquote('max(27vw,30rem)');

                img {
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }

    @media #{$media-tablet} { 
        .s1 {
            &__postcard {
                grid-template-columns: 1fr;
            }
        }
    }

    @media #{$media-mobile} { 
        .s3 {
            &__gallery {
                grid-template-columns: 1fr;
            }
        }
    }

    @media #{$media-mobile-sm} { 
        .s1 {
            &__postcard {
                &__text {
                    &__bottom {
                        .speakers {
                            column-count: 1;
                        }
                    }
                }
            }
        }
    }
}