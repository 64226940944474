.footer {

    background-color: black;
    color: white;
    font-size: 1.6rem;
    line-height: 18/16;
    opacity: 0;

    h5 {
        font-weight: bold;
        margin-bottom: 1em;
    }

    // anchor format
    li a {
        text-decoration: none;
        font-weight: normal;
    }

    // @include a-to-orange;

    
    .wrap {
        display: grid;
        grid-template-columns: 1fr auto;
        grid-template-areas: 'logo social'
                             'texts newsletter';
        gap: 0 7vw;

        &__logo { grid-area: logo;
            @include pad-bottom;
        }

        &__texts { grid-area: texts;
            display: flex;
            gap: 0 3vw;

            &__network {
                flex-shrink: 0;
            }
        }

        &__social { grid-area: social;
            display: flex;
            @include pad-bottom;

            a {
                margin-right: 1rem;
            }
        }

        &__newsletter { grid-area: newsletter;
            max-width: 42rem;
        }
    }

    &__signature {
        padding-top: 1rem;
        padding-bottom: 5rem;
    }
}


@media #{$media-tablet} {

    .footer {
        .wrap {

            display: block;

            &__logo {
                padding-bottom: 5rem;
            }

            &__texts {
                display: block;
                padding-bottom: 5rem;

                &__about {
                    padding-bottom: 2em;
                }
            }

            &__social {
                padding-bottom: 2em;
            }

            &__newsletter {
                @include pad-bottom;
            }
        }

        &__signature {
            padding-bottom: 10rem;
        }
    }
}

// mobile banner

.mobileregister {
    display: none;
    @media #{$media-mobile} { display: flex; }

    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 6.4rem;
    z-index: 101;

    align-items: center;
    justify-content: center;

    background-color: $orange;
    color: white;

    img {
        margin-left: 0.5rem;
        transition: margin-left 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
        padding-bottom: 0.3rem;
        width: 1.65rem;
        vertical-align: middle;
    }

    a:hover, a:focus, a:active {
        img {
            margin-left: 2rem;
        }
    }

    transition: opacity 0.5s ease;
    &.expanded {
        opacity: 0;
        pointer-events: none;
    }
}

// special cases

.template-tickets,
.template-agenda {
    .mobileregister {
        display: none!important;
    }
}

// agenda banner (sponsor)

.agendabanner {
    display: none;

    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    // height: 6.4rem;
    z-index: 101;

    background-color: white;

    .defaulttitle {
        width: 100%;

        .h2 {
            font-size: unquote("max(2.2vw,1.5rem)");
            line-height: 1;
        }

        img {
            max-width: unquote("max(10vw,7rem)");
            margin-left: 1.5rem;
        }

        .container {
            padding-top: 2rem;
            padding-bottom: 2rem;

            display: flex;
            align-items: center;
            justify-content: center;

            @media #{$media-mobile} { 
                padding-top: 1.5rem;
                padding-bottom: 1.5rem;
            }
        }

        &:hover, &:focus, &:active {
            .mask-hover {
                opacity: 1;
            }
            .mask:not(.mask-hover) {
                opacity: 0;
            }
        }
    }

    transition: opacity 0.5s ease;
    &.expanded {
        opacity: 0;
        pointer-events: none;
    }
}


.template-agenda {
    .agendabanner {
        display: flex;
    }
}