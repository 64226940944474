.template-page-sponsors {

    // sponsors

    .s0 {
        margin-top: 50px;

        &__wrap {
            @include container;
        }

        .sponsor {
            @include container;
            border-bottom: 0.05em solid #000;
            &:last-of-type {
                border-bottom: none;
            }

            display: grid;
            grid-template-columns: 400px 500px;
            justify-content: center;
            margin: 2rem auto;

            @media #{$media-tablet} {
                grid-template-columns: 40% auto;
            }
            @media #{$media-mobile} {
                display: block;
            }

            &__logo {
                padding: 0 4rem;
                text-align: center;

                img {
                    max-height: 110px;
                    width: auto;
                }

                @media #{$media-mobile} {
                    padding-left: 0;
                    padding-top: 0;
                    padding-bottom: 4rem;
                    text-align: left;
                }
            }
            &__text {
                margin-left: 4rem;
                @media #{$media-mobile} {
                    margin-left: 0;
                }

                &__links {
                    margin-top: 4rem;
                    margin-bottom: 2rem;
                    text-align: right;
                    a {
                        text-decoration: none;
                        color: $orange;
                        &:hover, &:focus, &:active {
                            color: black;
                        }
                    }
                }
            }
        }
    }

    // catalysts
    .s1 {
        overflow: hidden;
        &__wrap {
            position: relative;
            height: fit-content;
            display: grid;
            grid-template-columns: 300px 100vw;
            min-height: 400px;

            //ui
            left: calc(-300px - #{$pad-h});
            @media #{$media-tablet} {
                left: calc(-300px - #{$pad-h-t});
            }
            @media #{$media-mobile} {
                display: block;
                left: 0;
            }
            transition: left 0.7s ease, min-height 0.7s ease;
            &.transformed {
                left: 0;
            }
        }
        .catalysts-page {
            height: fit-content;
            height: 300px;

            @media #{$media-mobile} {
                position: fixed;
                z-index: 999;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                pointer-events: none;
                margin: 0;
                &.active {
                    pointer-events: auto;
                    background-color: rgba(255,255,255,0.45);
                    backdrop-filter: blur(10px);
                    -webkit-backdrop-filter: blur(10px);
                }
            }

            .catalyst {
                padding: 3.2rem 4rem;
                @include margin-container;
                background-color: $gray;
                border-radius: 20px;

                &__logo {
                    padding: 0 2rem 2rem 2rem;
                    max-width: 50vw;
                }
                &__text {
                    margin-top: 2rem;

                    &__links {
                        margin-top: 2rem;
                        text-align: right;
                        a {
                            text-decoration: none;
                            color: $orange;
                            &:hover, &:focus, &:active {
                                color: black;
                            }
                        }
                    }
                }

                // ui
                position: absolute;
                top: 0;
                left: 0;
                width: 300px;
                height: fit-content;
                z-index: 0;
                opacity: 0;
                transition: opacity 0.5s ease;
                &.active {
                    z-index: 10;
                    opacity: 1;
                }
                #popup-close {
                    display: none;
                }

                @media #{$media-mobile} {
                    // width: unset;
                    top: 50%;
                    left: 50%;
                    width: calc(100vw - 4rem);
                    max-width: 500px;
                    transform: translate(-50%, -50%);

                    &.active {
                        z-index: 10;
                        opacity: 1;
                    }

                    #popup-close {
                        display: block;
                        right: 2rem;
                        top: 2rem;
                        color: black;
                    }
                }
            }
        }

        .catalysts-grid {
            width: 100vw;
            height: fit-content;
            @include margin-container;
            @include pad-left;

            @media #{$media-mobile} {
                justify-content: space-around;
                width: unset;
            }

            .gridlogos__logo {         
                cursor: pointer;
            }
        }
    }

    // NETWORK PARTNERS (LOGOS)
    .s6 {

        margin-bottom: 3rem;

        .h2 {
            // border-bottom: 1px solid black;
            margin-bottom: 2rem;
            padding-bottom: 0.5rem;
            // @include fw-medium;
            // max-width: 20rem;

            @media #{$media-tablet} { 
                margin-top: 1rem;
                margin-bottom: 3rem;
                padding-bottom: 2rem;
            }

        }
    }
}