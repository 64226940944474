div.widgets {

    display: none;

    .wrapper {
        overflow-y: scroll;

        position: absolute;
        top: 15%;
        left: 20%;
        width: 60%;
        height: 70%;

        background-color: white;
        @include container;
        padding-top: 4.5rem;

        @media #{$media-laptop} { 
            left: 15%;
            width: 70%;
        }

        @media #{$media-tablet} { 
            top: 10%;
            left: 10%;
            width: 80%;
            height: 80%;
        }

        @media #{$media-mobile} { 
            top: 8rem;
            left: 1.5rem;
            width: calc(100% - 3rem);
            height: calc(100% - 16rem);
        }

    }
    
    #popup-close {
        top: calc(15% + 10px);
        right: calc(20% + 18px);
        color: black;

        @media #{$media-laptop} { 
            right: calc(15% + 18px);
        }

        @media #{$media-tablet} { 
            top: calc(10% + 10px);
            right: calc(10% + 18px);
        }

        @media #{$media-mobile} { 
            top: calc(8rem + 10px);
            right: calc(1.5rem + 18px);
        }
    }
    &::after {
        background-image: radial-gradient(ellipse farthest-side at top right, $orange 0%, white 165%);
        animation-duration: 0.5s;
    }
}


#widget-event-apply {

    form {
        .field {
            margin-bottom: 2rem;

            input, textarea {
                max-width: 100%;
                width: 40rem;
            }
        }

        input[type="submit"] {
            display: inline-block;
            cursor: pointer;
        
            width: fit-content;
            min-width: 11rem;
            height: 4.1rem;
            line-height: 4.1rem;
            text-align: center;
        
            padding: 0 1.5rem;
            margin: 2rem 0;
        
            background-color: $orange;
            color: white;
        
            @include ls;
            @include fw-medium;
        
        
            &:hover, 
            &:active, 
            &:focus {
                transition: background-color 0.2s ease-out;
                background-color: black;
                mix-blend-mode: darken;
            }
        }
    } 
}

.template-widget-common {
    background-image: linear-gradient(to bottom, $orange 0%, #fff 200px);
    background-repeat: no-repeat;

    iframe {
        margin-bottom: 12rem;

        @media #{$media-mobile} { 
            margin-bottom: 0;
        }
    }

    .bizzabo-sections-widget {
        min-height: calc(100vh - #{$header-h});
    
        @media #{$media-tablet} {
            min-height: calc(100vh - #{$header-h-m} - #{$mobilebar-h-m});
        }
    }
}

.template-widget-limheight {

    iframe {
        height: 800px!important;
        height: 100vh!important;
        height: calc(100vh - #{$header-h})!important;

        @media #{$media-mobile} { 
            height: calc(100vh - #{$header-h-m} - #{$mobilebar-h-m})!important;
        }
    }
}