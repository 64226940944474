.button {
    display: inline-block;
    cursor: pointer;

    width: fit-content;
    min-width: 11rem;
    height: 4.1rem;
    line-height: 4.1rem;
    text-align: center;

    padding: 0 1.5rem;
    margin: 2rem 0;

    background-color: $orange;
    color: white;

    @include ls;
    @include fw-medium;


    &:hover, 
    &:active, 
    &:focus {
        transition: background-color 0.2s ease-out;
        background-color: black;
        mix-blend-mode: darken;
    }

    &--transparent {
        background-color: transparent;
        color: inherit;

        border-width: 1px;
        border-style: solid;
        border-radius: 0;
        border-color: black;
        mix-blend-mode: darken;
        
        &:hover, 
        &:active, 
        &:focus {
            transition: background-color 0.2s ease-out, color 0.2s ease-out;
            color: white;
        }
    }
}


