#newsletter-form {

    form {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 2rem;
        margin-bottom: 2rem;
    
        border-bottom: 1px solid white;
    
        padding: 7px 0;

        position: relative;

        & > *:first-child {
            flex-grow: 1;
        }
    }

    input[type="email"] {
        width: 100%;
        color: white;

        &::-webkit-input-placeholder { color: white; }
        &::-ms-input-placeholder { color: white; }
        &:-ms-input-placeholder { color: white; }
    }

    &:hover,
    input[type="email"]:hover,
    input[type="email"]:active,
    input[type="email"]:focus {
        color: $orange;

        &::-webkit-input-placeholder { color: $orange; }
        &::-ms-input-placeholder { color: $orange; }
        &:-ms-input-placeholder { color: $orange; }

        & + input[type="submit"] {
            width: 3rem;
            filter: invert(62%) sepia(32%) saturate(4548%) hue-rotate(360deg) brightness(101%) contrast(108%);
        }
    }

    input[type="submit"] {
        display: inline-block;
        width: 2.2rem;
        height: 1.9rem;

        background-image: url('../img/arrows/right-white-mail.svg');
        background-position: 0;
        background-repeat: no-repeat;
        background-size: contain;

        cursor: pointer;
        transition: width 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
    }

    input[type="email"]:hover,
    input[type="email"]:active,
    input[type="email"]:focus {
        &::after {
            opacity: 1;
        }
    }

    input[type="submit"]:hover,
    input[type="submit"]:active,
    input[type="submit"]:focus {
        // background-image: url('../img/arrows/right-orange-mail.svg');
        filter: invert(62%) sepia(32%) saturate(4548%) hue-rotate(360deg) brightness(101%) contrast(108%);
        width: 3rem;
    }

    .hs-error-msgs {
        position: absolute;
        top: 3.5rem;
    }
}