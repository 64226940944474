.gridschedule {
    display: grid;
    grid-template-columns: repeat(3, 1fr);

    @media #{$media-laptop} {
        @include pad-left;
        @include pad-right;
        gap: 4vw;
    }

    @media #{$media-tablet} {
        gap: 3vw;
        grid-template-columns: repeat(2, 1fr);

    }

    @media #{$media-mobile} {
        display: block;
    }

    &__item {
        @include container;

        &__image {
            border-radius: 1rem;
            margin-bottom: 2.5rem;

            @media #{$media-mobile} {
                width: 400px;
            }
        }
        .h5 {
            // font-size: 2rem;
            // line-height: 24/20;
            // @include ls;
            font-weight: bold;

            // padding-bottom: 0.3em;

            // @media #{$media-mobile} { padding-bottom: 0.2em; }
        }
        .h4 {
            @include fw-medium;
            padding-bottom: 0.4em;

            @media #{$media-mobile} {
                // font-size: 3.2rem;
                line-height: 1;
                // @include ls-50;
                padding-bottom: 0.2em;
            }
        }
        &__description {
            @include richtext;
            @include richtext-small;

            a.button {
                margin: 1.2rem 0;
                color: #fff;
                display: inline-block;
                text-decoration: none;

                &:active, &:focus, &:hover {
                    color: #fff;
                }
            }


        }
        margin-bottom: 4rem;

        @media #{$media-laptop} {
            padding-left: 0;
            padding-right: 0;
        }

        @media #{$media-mobile} {
            margin-bottom: 0rem;
        }
    }

    @media #{$media-mobile} {
        margin-bottom: 5rem;
    }
}


.defaultcardgrid {
    @include container;
    margin-bottom: 4rem;

    display: grid;
    // grid-template-columns: 1fr 1fr 1fr;
    grid-template-columns:repeat(auto-fit,minmax(380px,1fr));

    @media #{$media-mobile-sm} {
        grid-template-columns: auto;
    }

    gap: unquote("max(3vw, 3rem)");
}

// GRID Logos

.gridlogos {

    display: grid;
    grid-template-columns: repeat(auto-fit,minmax(25rem,1fr));
    gap: 3vw;
    align-items: center;
    justify-items: center;

    @media #{$media-tablet} { 
        grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
    }
    @media #{$media-mobile} { 
        grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
    }

    &__logo {

    }
}