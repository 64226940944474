.marquee-parent {
    overflow: hidden;
    height: 19rem; 
    //height: 13.4rem;

    display: flex;
    align-items: center;
    width: 100%;

    transition: background-color 0.1s linear;

    @media #{$media-laptop} {
        height: 15rem;

    }
    @media #{$media-tablet} {
        height: 13.5rem;
    }

    .marquee {
        display: block;
        height: min-content;

        font-size: 9rem;
        @include ls-50;
        // font-size: 6rem;
        // @include ls;

        img {
            margin: 0 2rem 0 4rem;
            width: 6rem;
        }

        @media #{$media-laptop} {
            font-size: 7.5rem;
            @include ls-50;
        }
        @media #{$media-tablet} {
            font-size: 6rem;
            @include ls-50;
        }

        p {
            margin-block-start: 0;
        }

        &__text {
            
            // width: max-content!important;
            min-width: 100vw!important;
            height: 100%!important;
        }

        .black {
            transition: color 0.1s linear;
        }
    }
}    

.marquee-parent:hover,
.marquee-parent:focus,
.marquee-parent:active {
    background-color: black;
    .black {
        color: $orange!important;
    }
}