
#popup, .popup {
    position: fixed;
    max-height: 0;
    height: 100%;
    width: 100%;
    bottom: 0;
    left: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    overflow: hidden;
    transition: max-height 0.9s cubic-bezier(0.075, 0.82, 0.165, 1);
    z-index: 200;

    // backdrop
    &::after {
        content:'';
        position: fixed;
        // background-color: rgba(0,0,0,0.75);
        background-image: radial-gradient(ellipse farthest-side at top right, black 0%, transparent 250%);
        opacity: 0;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;

        // cursor: pointer;
        animation: appear 1.3s ease 0.5s 1;
        animation-fill-mode: forwards;
    }

    > * {
        z-index: 201;
        position: relative;
    }
}

#popup-close {
    // content:'×';
    position: fixed;
    top: calc(15% + 10px);
    right: calc(15% - 18px);
    font-size: 2rem;
    line-height: 1;
    color: white;
    cursor: pointer;
    opacity: 0;

    animation: appear 1.5s ease 0.5s 1;
    animation-fill-mode: forwards;
}

@keyframes appear {
    100% { opacity: 1;}
} 