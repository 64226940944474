form {

    input {
        // color: black;

        &::-webkit-input-placeholder { opacity: 1; }
        &::-ms-input-placeholder { opacity: 1; }
        &:-ms-input-placeholder { opacity: 1; }

        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus,
        &:-webkit-autofill:active {
            transition: background-color 5000s ease-in-out 0s;
            -webkit-box-shadow: 0 0 0px 1000px transparent inset;
            -webkit-text-fill-color: $orange !important;
        }

        border-bottom: 1px solid black;
    }

    .hs-error-msgs {
        color: red;
    }
}


.template-post-event {

    .s2 {
        &__forms {

            display: grid;
            grid-template-columns: auto 1fr;
            align-items: center;
            gap: 1.6rem;
            width: 100%;
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;

            form {
                margin-bottom: 0;
            }

            #form-sessions-select {
                display: grid;
                grid-template-columns: 1fr 1fr 1fr 1fr auto;
                align-items: center;
                gap: 1.6rem;
                width: 100%;
            }

            &__search {

                position: relative;
                width: 25vw;

                input {
                    border: 1px solid black;
                    border-radius: 2rem;
                    padding: 0 2rem;
                    height: 4rem;
                    line-height: 4rem;
                    width: 100%;
                    font-size: 2.4rem;
                    @include ls-50;

                    &::placeholder {
                        color: inherit;
                    }

                    @media #{$media-mobile} { 
                        font-size: 2rem;
                        @include ls-50;
                    }
                }

                button {
                    position: absolute;
                    top: 0;
                    right: 0;
                    height: 100%;
                    width: 6rem;
                    &::after {
                        position: absolute;
                        top: 20%;
                        right: 20%;
                        left: 20%;
                        bottom: 20%;
                        content:'';
                        background-image: url('../img/search.svg');
                        background-repeat: no-repeat;
                        background-size: 60%;
                        background-position: center;
                        background-color: white;

                        // ui
                        transition: transform 0.2s ease;
                    }

                    &:hover, &:active, &:focus {
                        &::after {
                            transform: scale(1.08);
                        }
                    }
                }

                // animation

                transition: width 0.8s ease-in-out;

                &.closed {
                    width: 6rem;
                }
            }

            .form-sessions-clear {
                height: 4rem;
                width: 4rem;
                display: flex;
                align-items: center;
                justify-content: center;
                padding-top: 0.2rem;

                border: 1px solid black;
                border-radius: 2rem;
                line-height: 4rem;
                font-size: 1.8rem;
                @include ls-50;
                font-weight: bold;

                // ui
                transition: transform 0.4s ease;

                &:hover, &:active, &:focus {
                    transform: scale(1.05);
                }


                // resp
                &.mobile {
                    display: none;
                }

                @media #{$media-mobile} { 
                    font-size: 1.5rem;
                    @include ls-50;
                }
            }
        }
    }

    // select2 customization
    // ---------------------

    // field width
    .select2-container--default {
        width: 100%!important;
    }

    // hide search
    .select2-container .select2-search--inline .select2-search__field {
        display: none;
    }

    // hide selection render
    .select2-container .select2-selection--multiple .select2-selection__rendered {
        // display: none;
    }

    // field
    .select2-container--default .select2-selection--multiple,
    .select2-container--default.select2-container--focus .select2-selection--multiple {
        border-width: 0;
        border-bottom: 1px solid black;
        border-radius: 0;
        padding: 0;
        cursor: pointer;
    }
    .select2-container .select2-selection--multiple .select2-selection__rendered {
        display: inline-flex;
        height: 100%;
        align-items: center;
        font-size: 2.4rem;
        @include ls-50;

        @media #{$media-mobile} { 
            font-size: 2rem;
            @include ls-50;
        }
    }

    // dropdown
    .select2-dropdown, 
    .select2-container--open .select2-dropdown--below {
        border-width: 0!important;
        border: 0 solid black;
        background-color: rgba(255, 255, 255, 0.65);
        backdrop-filter: blur(10px);
    }
    .select2-container--default .select2-results > .select2-results__options {
        max-height: 30rem;
    }
    .select2-container--default .select2-results__option--selectable {
        padding: 0.2rem 1rem;
        &:first-of-type {
            padding-top: 1rem;
        }
        &:last-of-type {
            padding-bottom: 1rem;
        }

        // dropdown items colors
        color: $graytext2;

        &.select2-results__option--highlighted {
            color: $graytext2;
            background-color: transparent;
            &:hover {
                color: black;
            }
        }

        &.select2-results__option--selected {
            background-color: transparent;
            color: $orange;
        }
    }


    @media only screen and (max-width : 1300px) { 
        .s2 {
            &__forms {
                grid-template-columns: 1fr;
                gap: 2.5rem;

                #form-sessions-search {
                    display: flex;
                    width: calc(50% - 1.5rem);
                }

                &__search {
                    width: 100%;
                }

                .form-sessions-clear {
                    &.mobile {
                        display: flex;
                        margin-left: 1rem;
                        min-width: 4rem;
                    }
                    &.desktop {
                        display: none;
                    }
                }
            }
        }
    }

    @media only screen and (max-width : 800px) { 
        .s2 {
            &__forms {
                #form-sessions-search {
                    width: 100%;
                }

                #form-sessions-select {
                    grid-template-columns: 1fr 1fr;
                }
            }
        }
    }

    @media #{$media-mobile-sm} { 
        .s2 {
            &__forms {
                #form-sessions-select {
                    grid-template-columns: 1fr;
                }
            }
        }
    }
}