.template-faqs {

    .s0 {
        @include container;
        display: flex;
        align-items: center;
        min-height: 22.5rem;
    }

    .s1 {
        display: flex;
        flex-wrap: wrap;
        width: 100%;

        &__item {

            // layout
            display: block;
            min-width: 34rem;
            min-height: 25rem;
            flex-grow: 1;
            flex-basis: 24vw;

            &__wrap {
                text-align: center;

                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;

                width: 100%;
                height: 100%;
                min-width: 34rem;
                min-height: 25rem;

                // animation
                // background: linear-gradient(294deg, white 14%, $orange 61%);
                background: radial-gradient(ellipse farthest-side at top left, $orange 33%, transparent 100%);
                background-size: 300% 300%;
                background-position: 80% 40%;
                transition: background-position 0.3s linear;

                h4 {
                    @include fw-medium;
                    padding-bottom: 0.1em;
                }
            }

            &:hover .s1__item__wrap,
            &:focus .s1__item__wrap,
            &:active .s1__item__wrap {
                background-position: 0 0;
                transition: background-position 0.8s linear;
            }
        }

        @media #{$media-mobile} { 
            &__item {
                min-width: 19rem;
                min-height: 20rem;
                &__wrap {
                    min-width: 19rem;
                    min-height: 20rem;
                }
            }
        }
    }

    .s2 {
        display: grid;
        grid-template-columns: 32% 1fr;
        padding-top: 3rem;

        @media #{$media-mobile} { grid-template-columns: 1fr; }

        &__left {

            position: sticky;
            top: calc(#{$header-h} - 2px);
            left: 0;
            height: fit-content;

            @media #{$media-mobile} { display: none; }


            &__link { 
                text-decoration: none;
                line-height: 3.2rem;
                @include ls;
                @include fw-medium;
            }

            .active, a:hover, a:focus, a:active {
                transition: color 0.2s ease;
                color: $orange;
            }
        }
        &__right {

            // @media #{$media-mobile} { max-height: fit-content; }

            &__wrap {

                .h4 {
                    padding-bottom: 0.15em;
                    margin-bottom: 0.5em;
                    border-bottom: 1px solid black;
                    padding-top: 1em;
                    @include fw-medium;
                    @include ls;
                }

                &:first-of-type .h4 {
                    padding-top: 0;
                }

                .faq {
                    padding-left: 3rem;
                    &__question {
                        font-weight: bold;
                        margin-top: 1.5em;
                        position: relative;
                        cursor: pointer;

                        &::before {
                            content:'';
                            background-image: url('../img/arrows/down-black-faq.svg');
                            background-position: left;
                            background-repeat: no-repeat;
                            background-size: contain;

                            width: 2.1rem;
                            height: 1.9rem;

                            position: absolute;
                            left: -3rem;
                            top: 0;

                            transform: rotate(-180deg);
                            transition: transform 0.2s cubic-bezier(0.075, 0.82, 0.165, 1);
                        }

                        &.collapsed::before {
                            transform: rotate(0);
                            transition: transform 1.2s cubic-bezier(0.075, 0.82, 0.165, 1);
                        }

                        &.collapsed + .faq__answer {
                            display: none;
                        }

                        &.expanded + .faq__answer {
                            animation: expand 2s linear;
                            animation-fill-mode: forwards;
                            @keyframes expand {
                                to {
                                    max-height: 100%;
                                }
                            }
                        }
                    }
                    &:first-of-type .faq__question {
                        margin-top: 0;
                    }
                    &__question, p {
                        margin-bottom: 0.7em;
                    }
                    p:last-of-type {
                        margin-bottom: 0;
                    }
                    &__answer {
                        overflow: hidden;
                        max-height: 0%;

                        ul {
                            padding-bottom: 1.4em;
                    
                            li {
                                padding-bottom: 1em;
                            }
                        }
                    }
                }
            }
        }
    }
}