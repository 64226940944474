.template-home {

    main {
        margin-top: 0;
        border-bottom: 1px solid white;
    }

    .header {
        &__xtrabar {
            display: block;
        }
    }

    // VIDEO
    .s0 {
        background-color: black;

        .video-full {
            border-bottom: 1px solid black; //bug in iphone
        }
        
        padding-top: $header-h;

        @media #{$media-tablet} {
            padding-top: calc(#{$header-h-m} + #{$mobilebar-h-m});
        }
    }

    // INTRO COVER
    .s1 {

        // @include fill-space;
        min-height: calc(100vh - #{$data-h});            
        @media #{$media-tablet} { 
            min-height: unset;
            // height: fit-content;
        }
        @media #{$media-mobile} { 
            height: fit-content;
        }


        .container {
            z-index: 1;
        }

        &__title {
            font-size: 7rem;
            @include ls;
            line-height: calc(8 / 7);

            max-width: unquote("min(75%, 100rem)");
            margin-bottom: 3rem;

            @media #{$media-laptop} { 
                font-size: 5.5rem;
            }
            @media #{$media-tablet} { 
                font-size: 5rem; 
                max-width: 69%; 
            }
            @media #{$media-mobile} { 
                font-size: 4rem; 
                max-width: unset; 
            }
        }
        &__host {
            line-height: 1;
            @include ls;
            @include fw-medium;

            margin-bottom: 3rem;

            // @media #{$media-laptop} { font-size: 2.8rem; }
            // @media #{$media-tablet} { font-size: 2.6rem; }
            // @media #{$media-mobile} { font-size: 2.4rem; }
        }
        &__mask {
            &--image{
                bottom: 0;
                left: 0;
                background-repeat: no-repeat;
                background-position: right bottom;
                background-size: 55%;
                background-size: 70rem;

                @media #{$media-laptop} { background-size: 48rem; }
                @media #{$media-tablet} { background-size: 38rem; }
                @media #{$media-mobile} { background-size: 45rem; }
                @media #{$media-mobile-sm} { background-size: 35rem; }
            }
        }

        .text-small {
            font-size: 2.4rem;
            line-height: calc(24 / 20);
            padding-bottom: 0.3em;
            @include ls;
        


            @media #{$media-laptop} { max-width: 43rem; }
            @media #{$media-tablet} {
                font-size: 2.1rem;
                @include ls-50;
                padding-bottom: 0.2em;

                max-width: 40rem; 
                min-height: 32rem; 
                min-height: unset;
            }
            @media #{$media-mobile} { max-width: 100%; min-height: unset; padding-bottom: 42rem; }
            @media #{$media-mobile-sm} { padding-bottom: 32rem; }
        }
    }

    // DATA
    .s2 {

        .container {
            display: flex;
            justify-content: space-around;
            align-items: center;
            min-height: $data-h;

            @media #{$media-mobile} { 
                flex-direction: column;
                padding-top: 5rem;
                padding-bottom: 5rem;
            }
        }

        &__item {

            text-align: center;

            .h4 {
                @include ls;
                @include fw-medium;
            }
    
            &__image {
                margin-bottom: 1.2rem;
            }

            @media #{$media-laptop} {
                &__image {
                    height: 7rem;
                }
            }

            @media #{$media-tablet} { 
                &__image {
                    height: 6.5rem;
                    margin-bottom: 0.8rem;
                }
            }

            @media #{$media-mobile} { 
                margin-bottom: 8rem;
                
                &:last-of-type {
                    margin-bottom: 0;
                }
            }
        }
    }

    // EXPECT CARDS - DESKTOP
    .s3 {        
        @media #{$media-tablet} { display: none; }

        &.v-div {
            // min-height: 90rem;
            min-height: 86rem;
            height: fit-content;

            @media #{$media-laptop} {
                grid-template-columns: 38% 1fr;
            }

            .v-div__left {
                height: 100%;
            }
            .v-div__right {
                border-left: 1px solid rgba(0,0,0,0.2);
            }
        }

        &__listitem {
            border-top: 1px solid rgba(0,0,0,0.2);
            cursor: default;

            &__title {
                // font-size: 3.2rem;
                line-height: 1;
                // @include ls-50;
                @include fw-medium;

                position: relative;
                transition: color 0.6s ease;

                h4 {
                    max-width: 22rem; //two lines
                    padding-bottom: 0;
                }

                ::after {
                    content: '';
                    background-image: url('../img/arrows/right-green-expect.svg');
                    background-repeat: no-repeat;
                    background-size: contain;
                    position: absolute;
                    background-position: center;
                    right: 0;
                    top: 0;
                    width: 3.7rem;
                    height: 100%;
                    opacity: 0;
                }
            }

            &__text {

                max-height: 0;
                opacity: 0;
                overflow: hidden;
                // transition: max-height 0.9s linear, opacity 0.3s ease;
                transition: opacity 0.6s ease;
                @include text-small;

                > *:first-child {
                    padding-top: 1.5rem;
                }
            }

            &.active {
                .s3__listitem {
                    &__title {
                        color: $green;
        
                        ::after{
                            opacity: 1;
                            transition: opacity 0.6s ease; 
                        }
                    }
                    &__text {
                        max-height: 30rem;
                        opacity: 1;
                        transition: max-height 1.3s linear, opacity 1s ease;
                    }
                }
            }
        }

        &__image {
            position: absolute;
            height: 100%;
            width: 100%;
            left: -120%;
            top: 0;
            transition: left 2s ease;

            display: flex;
            align-items: center;
            justify-content: center;

            // &.permanent,
            &.active {
                left: 0;
            }

            video {
                opacity: 0;
                height: 100%;
                min-width: 100%;
                object-fit: cover;
                transition: opacity 0.5s ease;
            }
        }
    }

    // EXPECT CARDS - MOBILE
    .s3m {
        display: none;
        @media #{$media-tablet} { display: block; }

        &__title {

        }

        .swiper {
            border-bottom: 1px solid rgba(0,0,0,0.2);

            .swiper-wrapper {
                height: auto;
                cursor: default;

                .swiper-slide {
                    width: fit-content;

                    .h4 {
                        margin: 2rem 1rem 1rem 2rem;
                        display: block;

                        // cursor: default;
                        transition: color 0.7s ease;

                        width: min-content;
                        // font-size: 2.4rem;
                        line-height: 1;
                        // @include ls-50;
                        @include fw-medium;
                    }

                    &.active .h4,
                    .h4:hover {
                        color: $green;
                    }
                }
            }
        }

        &__cards {
            position: relative;

            &__item {
                position: absolute;
                top: 0;
                left: -120%;
                width: 100%;

                &__text {
                    line-height: 2rem;
                    background-color: white;
                }
                &__image {
                    width: unquote("calc(100vw - 10px)");
                    overflow: hidden;

                    height: 60vw;

                    @media #{$media-mobile} { 
                        height: 100vw;
                    }

                    video {
                        opacity: 0;
                        height: 100%;
                        width: auto;
                        transition: opacity 0.5s ease;

                        transform: translateX(calc(50vw - 50%));
                    }
                }

                // animation
                // opacity: 0;
                transition: left 1.2s ease;
                &.active {
                    opacity: 1;
                    left: 0;
                }
            }
        }
    }

    // SCHEDULE OF EVENTS
    .s31 {
        &__titlewrap {
            padding: 0.55rem 0;
    
            @media #{$media-mobile} {
                padding: 2rem 0;
            }
    
            .mask {
                z-index: -1;
            }
        }
    }


    // FEATURED SPEAKERS
    .s4 {
        .grid-speakers {
            grid-template-columns: repeat(auto-fit, minmax(330px, 1fr));

            @media only screen and (max-width : 920px) {
                grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
            }

            &__card {
                max-width: unset;
            }
        }
    }

    // MARQUEE


    // LOCATION
    .s5 {
        .v-div__left__title {
            flex-grow: 0;
            margin-bottom: 3rem;
            max-width: 40rem;

            @media #{$media-laptop} {
                max-width: 33rem;
            }

            @media #{$media-tablet} { 
                margin-top: 1rem;    
                max-width: 25rem;
            }
        }

        &__textabove {
            flex-grow: 1;

            @include ls;
            line-height: 3.4rem;
            padding-bottom: 4rem;

            a, b, strong {
                font-family: $fl;
                text-decoration: none;
                font-weight: normal;
                display: inline;
            }

            @include a-to-orange;



            @media #{$media-tablet} { padding-bottom: 3rem; }
        }
        &__textbelow {
            @include text-small;
        }
    }

    // NETWORK PARTNERS (LOGOS)
    .s6 {

        margin-bottom: 3rem;

        .h2 {
            // border-bottom: 1px solid black;
            margin-bottom: 2rem;
            padding-bottom: 0.5rem;
            // @include fw-medium;
            // max-width: 20rem;

            @media #{$media-tablet} { 
                margin-top: 1rem;
                margin-bottom: 3rem;
                padding-bottom: 2rem;
            }

        }

    }

    .s6bis {
        &__selectors {
            display: flex;
            width: fit-content;
            margin-left: auto;
            margin-right: 0;
            border-top: 1px solid $gray;
            border-bottom: 1px solid $gray;

            @media #{$media-mobile} { 
                margin-left: 0;
                margin-right: auto;
            }

            &__item {
                padding: 1.5rem 2.5rem;
                font-size: 1.2em;
                background-color: white;
                color: black;
                &.active {
                    background-color: $orange;
                    color: white;
                }
            }
        }
        // &__wrap {
        //     padding-top: 0;

        //     .isotope-item {
        //         max-width: 160px;
        //         margin: unquote("max(1.4rem,3vw)") unquote("max(2rem,5vw)");
        //         display: grid;
        //         justify-items: center;
        //         align-items: center;
                
        //         @media #{$media-mobile-sm} { 
        //             max-width: 100%;
        //             margin: unquote("max(1.4rem,3vw)") 0;
        //         }
        //     }
        // }
        margin-bottom: 40px;

        .home-sponsor img {
            object-fit: contain;
            max-width: 240px;
            max-height: 100px;
        }

    }

    // LAST EDITION
    .s7 {

        .container {
            @media #{$media-mobile} { padding: 0; } 
        }

        &__topgrid {

            display: grid;
            grid-template-columns: 1fr calc(50% - 0.75rem); //half padding
            grid-template-areas: 'text picture';

            @media #{$media-mobile} { 
                grid-template-columns: 1fr;
                grid-template-areas: 'picture' 'text';
            }   
    
            &__left {
                max-width: 47rem;
                margin-right: 5rem;
                grid-area: text;
                min-height: unset;

                @media #{$media-mobile} { 
                    @include container; 
                } 

                .h2 {
                    margin-bottom: 0.5em;
                }

                .above {
                    @include text-small;

                    font-size: 2.4rem;
                    line-height: calc(24 / 20);
                    padding-bottom: 0.3em;
                    @include ls;
                
                    @media #{$media-tablet} {
                        font-size: 1.8rem;
                        letter-spacing: 0;
                        padding-bottom: 0.2em;
                    }

                    @media #{$media-mobile} { 
                        margin-top: 0.8rem;
                        margin-bottom: 7rem;
                    }   
                }
        
                .below {
                    padding: 0;

                    // text small
                    flex-grow: 1;
                    display: flex;
                    align-items: flex-end;
                    max-width: 30rem;

                    // font-size: 2.4rem;
                    // @include ls;
                    @include fw-medium;
                    line-height: calc(2.6 / 2.4);

                    margin-bottom: 1rem;
                    margin-top: 3rem;

                    a {
                        display: inline;
                        color: $orange;
                    }

                    a:hover, a:focus, a:active {
                        text-decoration: none;
                    }

                    @media #{$media-mobile} { 
                        margin-bottom: 0;
                    } 
                }
            }

            &__right {
                width: 100%;
                // height: 47rem;
                grid-area: picture;

                @media #{$media-mobile} { 
                    // height: 70vw;
                } 
            }

            padding-bottom: 1.5rem
        }

        &__talksgrid {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            gap: 1.5rem;

            @media #{$media-tablet} {
                grid-template-columns: repeat(2, 1fr);
            }

            @media #{$media-mobile} { 
                @include pad-left;
                @include pad-right;
                padding-bottom: 4rem;
                grid-template-columns: 1fr;
            } 

            &__item {
                position: relative;
                overflow: hidden; //scale contained
                cursor: pointer;

                &__text {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    padding: 1.5rem 3rem;

                    // font-size: 2.4rem;
                    // @include ls;
                    @include fw-medium;
                    line-height: calc(2.8 / 2.4);


                    transition: color 0.3s ease;

                    .h4 {
                        padding-bottom: 0;

                        @media #{$media-laptop} {
                            font-size: 2.4rem;
                            @include ls;
                        }
                    }
                }

                &__image {
                    transition: transform 0.3s ease;

                    img {
                        width: 100%;
                    }
                }

                &:hover, 
                &:active, 
                &:focus {
                    .s7__talksgrid__item {
                        &__text {
                            color: $orange;
                        }
                        &__image {
                            transform: scale(1.1);
                        }
                    }
                }
            }
        }
    }
}