.mask {
    position: absolute;
    width: 100%;
    height: 100%;
    pointer-events: none;
}

.mask-parent {
    position: relative; //mask parent
    overflow: hidden;
}

.loader__mask {
    &--orange{
        height: 200%;
        width: 200%;
        left: 0;

        background: radial-gradient(ellipse farthest-side at top right, #FF5300 0%, transparent 100%);
        z-index: -1;

        animation-name: move;
        animation-duration: 10s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
        animation-delay: 0.5s;

        @keyframes move {     
            33% {
                height: 100%;
                width: 200%;
                left: -100%;
            }
            50% {

                opacity: 0.3;
                height: 200%;
                width: 200%;
                left: 0;
            }
            66% { 
                height: 100%;
                width: 200%;
                left: -100%;
            }
        }

    }    
    &--blue{
        top: 0;
        height: 100%;
        right: 0;
        background: radial-gradient(ellipse farthest-side at center center, #66A9FF 0%, transparent 100%);        z-index: -1;

        opacity: 0;

        animation-name: blue;
        animation-duration: 6s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
        // animation-direction: alternate;
        animation-delay: 2s;

        @keyframes blue {     
            33% {
                opacity: 0.4;
            }
        }
    }
}

.template-home {

    // HOME COVER
    .s1__mask { 
        &--orange{
            height: 37rem;
            bottom: 0;
            left: 0;
            background:radial-gradient(ellipse farthest-side at bottom left, $orange 0%, transparent 100%);

            @media #{$media-mobile} { 
                background:radial-gradient(ellipse farthest-side at bottom left, $orange 0%, transparent 70%);
                opacity: 0.6;
            }
        }
        &--blue{
            height: 27%;
            width: 40%;
            bottom: 0;
            right: 0;
            background:radial-gradient(ellipse farthest-side at bottom right, $blue 0%, transparent 100%);

            @media #{$media-mobile} { 
                height: 15%;
                width: 20rem;
                background:radial-gradient(ellipse farthest-side at bottom right, $blue 0%, transparent 100%);
                opacity: 0.8;
            }
        }
    }

    // SCHEDULE OF EVENTS
    .s31__mask {
        &--orange{
            height: 400%;
            bottom: -133%;
            right: -80%;
            width: 150%;
            background: radial-gradient(ellipse farthest-side at center right, $orange 0%, transparent 100%);
            @media #{$media-mobile} { 
                height: 140%;
                bottom: -50%;
                opacity: 0.4;
            }
        }    
        &--blue{
            top: 0;
            height: 270%;
            right: 0;
            background:radial-gradient(ellipse farthest-side at top center, $blue 0%, transparent 57%);

            @media #{$media-mobile} { 
                height: 150%;
                width: 135%;
                background:radial-gradient(ellipse farthest-side at top center, $blue 0%, transparent 100%);
                opacity: 0.7;
            }
        }
    }
}

// NETWORK PARTNERS

.template-home,
.template-page-sponsors {
    .s6__mask {
        &--orange{
            top: 0;
            background:radial-gradient(ellipse farthest-corner at top left, $orange 0%, transparent 52%);
            z-index: -1;
            opacity: 0.3;
        }
        &--pink{
            top: 0;
            background:radial-gradient(ellipse farthest-corner at 66% -30%, $pink 0%, transparent 45%);
            z-index: -1;
            opacity: 0.8;
        }
    }
}

// SPEAKERS

.speakers-section {
    &__mask--orange{
        height: 400%;
        bottom: -133%;
        right: -80%;
        width: 150%;
        background: radial-gradient(ellipse farthest-side at center right, $orange 0%, transparent 100%);
        @media #{$media-mobile} { 
            height: 140%;
            bottom: -50%;
            opacity: 0;
        }
    }
    &__mask--green{
        top: 0;
        height: 142%;
        right: 0;
        background:radial-gradient(ellipse farthest-side at top center, $green 0%, transparent 57%);
        @media #{$media-mobile} { 
            height: 139%;
            width: 276%;
            background: radial-gradient(ellipse farthest-side at top right, #6EEBA7 0%, transparent 70%);
            opacity: 0.7;
        }
    }
    .grid-speakers .mask--bottom {
        top: 0;
        // vw comes from image size
        // background:radial-gradient(circle farthest-side at 50% 15vw, $orange 0%, transparent 61%);
        background: radial-gradient(circle farthest-side at 50% 186px, #FF5300 0%, transparent 56%);
        // @media #{$media-tablet} { 
        //     top: 6vw;
        // }
        // @media #{$media-mobile} { 
        //     top: 13.5vw;
        //     background:radial-gradient(circle farthest-side at 50% 17vw, $orange 0%, transparent 51%);
        // }
        // @media #{$media-mobile-sm} { 
        //     top: 15vw;
        //     background:radial-gradient(circle farthest-side at 50% 17vw, $orange 0%, transparent 32%);
        // }
    }
    .grid-speakers .mask--top {
        top: 0;
        background: radial-gradient(circle at 89% -19%, #FF5300 10%, transparent 52%);
    }
}


.template-single-speaker {
    .mask {
        top: 0;
        left: 0%;
        background: radial-gradient(ellipse farthest-side at top left, $orange 0%, transparent 100%);
        z-index: -1;
        opacity: 0.5;
    }
}

.defaulttitle {
    .mask {
        z-index: -1;
    }
    &__mask--orange, &__mask--green{
        height: 250%;
        bottom: 0;
        left: 0;
        width: 40%;
        min-width: 200px;
        background: radial-gradient(ellipse farthest-side at bottom left, $orange 0%, transparent 80%);
        opacity: 0.8;

        @media #{$media-mobile} { 
            // width: 163px;
        }
    }
    &__mask--pink, &__mask--blue {
        top: -170%;
        height: 270%;
        width: 62%;
        right: 10%;
        opacity: 0.89;
        background:radial-gradient(ellipse farthest-side at bottom center, $pink 0%, transparent 80%);
        @media #{$media-mobile} { 
            // height: 139%;
            // width: 276%;
            // background: radial-gradient(ellipse farthest-side at top right, #6EEBA7 0%, transparent 70%);
            // opacity: 0.7;
        }
    }
    &__mask--pink2, &__mask--blue2 {
        bottom: 0;
        height: 200%;
        opacity: 0.6;
        right: -100px;
        width: 500px;
        background:radial-gradient(ellipse farthest-side at bottom right, $pink 0%, transparent 57%);
        @media #{$media-mobile} { 
            // height: 139%;
            // width: 276%;
            // background: radial-gradient(ellipse farthest-side at top right, #6EEBA7 0%, transparent 70%);
            // opacity: 0.7;
        }
    }

    // animated

    &__mask--green {
        background: radial-gradient(ellipse farthest-side at bottom left, $green 0%, transparent 80%);
    }
    &__mask--blue {
        background:radial-gradient(ellipse farthest-side at bottom center, $blue 0%, transparent 80%);
    }
    &__mask--blue2 {
        background:radial-gradient(ellipse farthest-side at bottom right, $blue 0%, transparent 57%);
    }

    &.animated {
        .mask, .mask-hover {
            transition: opacity 0.5s ease;
        }
        .mask-hover {
            opacity: 0;
        }
    }
}

// experience page

.template-page-experience .s0{
    .mask-parent {
        overflow: visible;
    }
    .mask {
        top: 0;
        z-index: -1;

        &--blue{
            left: -25%;
            height: 80%;
            width: 110%;
            opacity: 0.65;
            background:radial-gradient(ellipse farthest-side at top center, $blue 0%, transparent 100%);
        }

        &--orange{
            right: 0;
            height: 110%;
            width: 55%;
            min-width: 300px;
            background: radial-gradient(ellipse farthest-side at top right, $orange 0%, transparent 80%);
            opacity: 0.46;
        }      
    }
}

// ple page
.template-page-ple {
    .s0 {
        .mask--black {
            background-image: linear-gradient(to bottom, #000 0rem, transparent 19rem, transparent 55%, #000 80%);
            top: 0;
            left: 0;
            z-index: -1;
        }
    }
    .s1 {
        .mask--white {
            background-image: linear-gradient(to top, white 0, transparent 15rem);
            bottom: 0;
            left: 0;
            z-index: 1;

            @media #{$media-mobile} {
                display: none;
            }
        }
    }
}

// POST EVENT

.template-post-event .s0 {
    .mask {
        z-index: -1;
    }
    .mask--orange{
        height: 400%;
        bottom: -133%;
        right: -80%;
        width: 150%;
        background: radial-gradient(ellipse farthest-side at center right, $orange 0%, transparent 100%);
        @media #{$media-mobile} { 
            height: 140%;
            bottom: -50%;
            opacity: 0;
        }
    }
    .mask--green{
        top: 0;
        height: 142%;
        right: 0;
        background:radial-gradient(ellipse farthest-side at top center, $green 0%, transparent 57%);
        @media #{$media-mobile} { 
            height: 139%;
            width: 276%;
            background: radial-gradient(ellipse farthest-side at top right, #6EEBA7 0%, transparent 70%);
            opacity: 0.7;
        }
    }
}