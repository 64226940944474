.main-menu {
    display: grid;
    grid-template-columns: repeat(4, auto);
    height: $header-h;

    @media #{$media-tablet} {
        height: $header-h-m;
    }

    &__item {

        display: flex;
        align-items: center;
        @include ls;
        @include fw-medium;

        a {
            height: 100%;
            width: 100%;
            display: flex;
            align-items: center;
            transition: none!important;
        }

        &--data {
            background-color: black;
            color: white;
            font-size: 1.6rem;
            line-height: 1.8rem;

            a {
                transition: color 0.25s ease!important;
                padding: 0 3rem;
            }

            @media #{$media-tablet} {
                display: none;
            }

            a {
                pointer-events: none;
                cursor: default;
                color: inherit!important;
            }
        }

        &--agenda {
            background-color: white;
            color: black;

            a { 
                padding: 0 3.6rem; 
                transition: background-color 0.25s ease, color 0.25s ease!important;
            }

            a:hover, a:focus, a:active {
                background-color: $gold;
                color: white!important;
            }

            // transition: background-color 0.5s ease, opacity 0.5s ease;

            &.expanded {
                opacity: 0;
                pointer-events: none;
            }

            @media #{$media-tablet} {
                display: none;
            }
        }

        &--agenda2 {
            background-color: $orange;
            color: white;

            a { 
                padding: 0 3rem; 
                transition: background-color 0.25s ease!important;
            }

            a:hover, a:focus, a:active {
                background-color: $gold;
                color: white!important;
            }

            // transition: background-color 0.5s ease, opacity 0.5s ease;

            &.expanded {
                opacity: 0;
                pointer-events: none;
            }

            @media #{$media-tablet} {
                display: none;
            }
        }

        &--register {
            background-color: $orange;
            color: white;

            a { 
                padding: 0 3.6rem; 
                transition: background-color 0.25s ease!important;
            }

            a:hover, a:focus, a:active {
                background-color: black;
                color: white!important;
            }

            // transition: opacity 0.5s ease;
            &.expanded {
                opacity: 0;
                pointer-events: none;
            }

            @media #{$media-tablet} {
                display: none;
            }
        }
    }
}

#hamburger, #hamburger-m {

    margin: 0 4rem;
    height: 1.8rem;

    @media #{$media-tablet} {
        margin: 0 2rem;
    }
    
    transition: all 0.2s ease-out;
    cursor: pointer;

    /* Styling the hamburger lines */
    .line {
        width: 24px;
        height: 2px;
        margin: 0 0 6px 0;
        background: black;
        transition: all 0.2s ease-out;
    }

    &.expanded {

        /* Styling the three lines to make it an X */
        .line:nth-child(1) {
            transform: rotate(45deg) translate(5.6px, 5.6px);
        }
        .line:nth-child(2) {
            opacity: 0;
        }
        .line:nth-child(3) {
            transform: rotate(-45deg) translate(5.6px, -5.6px);
        }
    }
}


// DESKTOP MENU 

.menupage {
    transform: translateY(-120%);
    opacity: 0;
    transition: transform 0.5s ease, opacity 0.5s ease;
    z-index: 99;

    &.expanded { 
        // display: flex;
        opacity: 1;
        transform: translateY(0);
    }

    position: absolute;
    width: 100%;
    height: fit-content;

    margin-bottom: 3rem;
    background-color: rgba(255,255,255,0.45);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    color: #000;
    font-size: 2rem;

    // floating 
    transform: translateY(-140%);
    z-index: 99;
    top: 100%;
    left: 0;
    width: 100%;
    box-shadow: -2rem 2rem 5rem rgba(0,0,0,0.3);

    @media #{$media-tablet} {
        top: 100%;
        right: unset;
        left: 0;
        width: 100vw;
        height: 100vh;
        z-index: 99;
        box-shadow: none;
        overflow: scroll;
        transition: opacity 0.6s ease-out, transform 0.6s ease-out;
        font-size: 4.5rem;
        @include ls-50;
        line-height: 5rem; 
        @include a-to-orange;
        padding-top: 3rem;

        background-color: rgba(255,255,255,1);
        backdrop-filter: none;
        -webkit-backdrop-filter: none;
    }

    &__wrap {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 6vw;
        width: fit-content;

        @media #{$media-tablet} {
            display: block;
        }

        li a {
            text-decoration: none;
            font-weight: normal;
        }
    }

    &__col {
        &__item {
            margin-bottom: 1rem;
        }
    }

    .js-menuaccordion-loader {
        cursor: pointer;
        transition: opacity 0.5s ease;

        &::after {
            display: inline-block;
            content: '';
            background-image: url('../img/arrows/down-black-faq.svg');
            // filter: invert(1);
            background-repeat: no-repeat;
            background-position: bottom center;
            background-size: contain;
            height: 0.7em;
            width: 1em;
            margin-left: 0.4em;
            transition: transform 0.4s ease;

            @media #{$media-tablet} {
                height: 0.5em;
                width: 0.8em;
                margin-left: 0.3em;
            }
        }
        &.open {
            opacity: 0.6;
            
            &::after {
                // opacity: 0.9;
                transform: rotate(180deg);
            }
        }
    }

    .accordion {
        // font-size: 0.95em;
        // line-height: 0.95;
        max-height: 230px;
        .menupage__col__item {
            padding-left: 1rem;
        }
        .menupage__col__item:first-of-type {
            padding-top: 1rem;
        }
        .menupage__col__item:last-of-type {
            margin-bottom: 0;
        }
    }
    

    &__social {
        display: flex;
        margin-top: 3rem;

        a {
            mask-repeat: no-repeat; 
            -webkit-mask-repeat: no-repeat;
            display: block;

            .mask-bg {
                width: 3.8rem; 
                height: 5rem; 
                background-color: #000;
                transition: background-color 0.3s ease;
            }

            &:hover, &:focus, &:active {
                .mask-bg {
                    background-color: $orange;
                }
            }

            @media #{$media-tablet} {

                mask-size: 3.7rem;
                -webkit-mask-size: 3.7rem;
                margin: 5rem 0 10rem 0;

                .mask-bg {
                    width: 7rem; 
                    height: 3.7rem; 
                }

            }
        }
    }
}