
.speakers-section {
    &__titlewrap {
        padding: 0.55rem 0;

        @media #{$media-mobile} {
            padding: 2rem 0;
        }

        .mask {
            z-index: -1;
        }
    }

    &__bottom {
        @include fw-medium;
        text-align: center;
        @include a-to-orange;
    }
}

.grid-speakers {

    display: grid;
    grid-template-columns: repeat(auto-fit, 270px);
    justify-content: space-around;
    grid-gap: 0 4rem;

    @media #{$media-laptop} {
        grid-gap: 0 3rem;
    }

    @media #{$media-tablet} {
        // grid-template-columns: repeat(3, 1fr);
        grid-gap: 0 2rem;
    }

    @media #{$media-mobile} {
        // grid-template-columns: repeat(2, 1fr);
        grid-gap: 0 1rem;
    }

    @media only screen and (max-width : 600px){
        justify-content: start;
    }

    &__card {

        // min-height: calc(23vw + 11rem);
        margin-bottom: 4rem;
        max-width: 270px;

        @media #{$media-tablet} {
            // min-height: calc(30vw + 13rem);
            margin-bottom: 3rem;
        }

        @media #{$media-mobile} {
            // min-height: calc(43vw + 13rem);
        }

        &__image{

            img {
                width: 100%;
                object-fit: cover;
                border-radius: 10px;
                filter: grayscale(1);
                transition: filter 0.3s ease;
            }

            // make element square
            img {
                position: relative;
            }
            img:before{
                content: "";
                display: block;
                padding-top: 100%;
            }
        }

        &__name {
            // font-size: 2.4rem;
            // @include ls;
            @include fw-medium;

            margin-top: 0.5em;
            // margin-bottom: 0.2em;
        }

        &__line {
            line-height: calc( 1.6 / 1.4);
        }

        .mask {
            transition: opacity 0.3s linear;

            &--bottom {
                opacity: 0.5;
                z-index: -1;
            }
            &--top {
                opacity: 0;
                border-radius: 10px;
            }
        }

        &:hover:not(.nolink),
        &:focus:not(.nolink),
        &:active:not(.nolink) {

            .grid-speakers__card__image {
                img {
                    filter: grayscale(0);
                }
            }

            .mask {
                &--bottom {
                    opacity: 1;
                }
                &--top {
                    opacity: 0.5;
                }
            }
        }
    }
}