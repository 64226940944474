/****************************************************************
/ GENERAL STYLING
/****************************************************************/

html {
    font-size: 62.5%;
    // font-size: clamp(6.25%, 0.85vw, 62.5%);
    // font-size: clamp(25%, 0.75vw, 41%);
}
body {
    position: relative; // be able to disable scrollbar in mobile devices when mobilemenu page is expanded
    margin: 0;
    font-family: $f;
    font-size: 1.8rem;
    font-weight: normal;
    @include ls;
    line-height: 1.111;
    background-color: white;

    @media #{$media-tablet} {
        font-size: 1.5rem;
    }

    // font rendering and aliasing
    text-rendering: geometricPrecision;
    font-smooth: antialiased;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    // scroll limit
    overscroll-behavior: none;
}

// TEXT

a {
    text-decoration: none;
    color: inherit;
    display: block;
}

p a, li a {
    text-decoration: underline;
    display: inline;
    font-weight: bold;
}

p, li {
    @include a-to-orange;
}

// a:hover, 
// a:active,
// a:focus {
//     opacity: 0.6;
//     transition: opacity 0.2s;
// }

.h2 {
    font-size: 9rem;
    @include ls-50;
    line-height: 7rem;
    @media #{$media-laptop} {
        font-size: 7.5rem;
        @include ls-50;
        line-height: 6rem;
    }
    @media #{$media-tablet} {
        font-size: 6rem;
        @include ls-50;
        line-height: 5rem;
    }
}

.h3 {
    font-size: 4rem;
    @include ls-50;
    padding-bottom: 0.3em;
    @media #{$media-tablet} {
        font-size: 3.2rem;
        @include ls-50;
        padding-bottom: 0.2em;
    }
}

.h4 {
    font-size: 3.2rem;
    @include ls-50;
    padding-bottom: 0.2em;
    @media #{$media-tablet} {
        font-size: 2.4rem;
        @include ls-50;
    }
}

.h5 {
    font-size: 2.4rem;
    line-height: calc(24 / 20);
    padding-bottom: 0.3em;
    @include ls;

    @media #{$media-tablet} {
        font-size: 1.8rem;
        letter-spacing: 0;
        padding-bottom: 0.2em;
    }
}

// general spacing

main {
    margin-top: $header-h;

    @media #{$media-tablet} {
        margin-top: calc(#{$header-h-m} + #{$mobilebar-h-m});
    }
}

/****************************************************************
/ UTILITIES
/****************************************************************/


.hidden { display: none; }


// SPACING TOOLS

.container {
    @include container;
}


// FORMAT TOOLS
// ---------------------------

.orange {
    color: $orange!important;
}

.black {
    color: black!important;
}

.lining {
    font-family: $fl;
}

.strikethrough {
    text-decoration: line-through;
}

.bg {
    &--black {
        color: white;
        background-color: black;
    }
    &--orange {
        background-color: $orange;
        color: white;
    }
}

.bgi {
    &--cover {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    &--contain {
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
    }
}

.text-small {
    flex-grow: 1;
    display: flex;
    align-items: flex-end;

    @include text-small;
    max-width: 47rem;
}

.text-small-parent {
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;

    min-height: $fill-h;

    @media #{$media-tablet} {
        min-height: $fill-h-m;
    }

    @media #{$media-mobile} {
        min-height: fit-content;
    }
}


// LAZY
// ------------------------------------

.lazy-image {
    opacity: 0;
    transition: opacity  0.6s ease;
}


// list creator
// ------------------------------------

.comma-list {

    a {
        display: inline;
    }
    span::after {
        content:', ';
    }
    span:nth-last-child(2)::after {
        content: ' and ';
    }
    span:last-of-type:after {
        content:'';
    }
}

// smart anchor (taking in account header height)
// ------------------------------------
a.anchor {
    display: block;
    position: relative;
    visibility: hidden;
    top: calc(1px - #{$header-h});
    @media #{$media-tablet} {
        top: calc(1px - #{$header-h-m} - #{$mobilebar-h-m});
    }
}

// animated scroll link
.js-scrollto,
.js-faq-selector,
.js-faq-box {
    cursor: pointer;
}

// loadmore banner
.loadmore-banner {

    display: none;

    &__wrap {

        background-color: $orange;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
    
        .h3 {
            
            padding-bottom: 0;
            line-height: 12rem;
            height: 12rem;
            display: inline-block;
            font-size: 2.4rem;
            @include ls;
            font-weight: 500;
            transition: transform 0.3s ease;
    
            @media #{$media-mobile} { 
                font-size: 2rem;
                @include ls;
            }
        }
    
        // ux
    
        cursor: pointer;
        transition: background-color 0.3s ease;
        &:hover, &:focus, &:active {
            background-color: $gold;
            .h3 {
                transform: scale(1.07);
            }
        }
    }
}

// play icon in videos

.play-after {
    position: relative;

    &::after {
        content:'';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-image: url('../img/play.svg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: 4rem;
        z-index: 10;

        //ui
        transition: opacity 0.3s ease;
    }

    &:hover {
        &::after {
            opacity: 0.4;
        }
    }

    &--big{
        ::after {
            background-size: 6rem;    
        }
    }
}
