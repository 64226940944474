.template-tickets {

    main {
        border-bottom: 1px solid white;
    }

    .s0 {

        min-height: 22.5rem;
        display: grid;
        grid-template-columns: 50% 50%;
        align-items: center;

        @media #{$media-mobile} {
            min-height: unset;
            grid-template-columns: 1fr;
        }

        > * {
            display: flex;

            height: 100%;
            width: 100%;

            @include pad-left;
            @include pad-right;
        }

        &__left {
            align-items: center;

            @media #{$media-mobile} { min-height: 13.7rem; }
        }

        &__right {
            font-size: 6rem;
            @include ls;
            line-height: 8/7;

            @media #{$media-mobile} { 
                min-height: 16.3rem; 
                font-size: 4rem;
                @include ls-50;
            }

            flex-direction: column;
            justify-content: center;

            strong {
                color: $orange;
                font-weight: normal;
                width: fit-content;

                // GRADIENT TEXT
                background-color: $orange;
                background-image: linear-gradient(90deg, $orange 32%, white 140%);

                background-size: 100%;
                -webkit-background-clip: text;
                -moz-background-clip: text;
                -webkit-text-fill-color: transparent; 
                -moz-text-fill-color: transparent;
            }
        }
    }

    .s1 {
        display: grid;
        grid-template-columns: repeat(4,25%);
        grid-template-areas: 'premium festival day virtual';

        @media #{$media-tablet} { 
            grid-template-columns: repeat(2,50%);
            grid-template-areas: 'premium day' 
                                 'festival virtual';
        }
        @media #{$media-mobile} { 
            display: block;
        }
        &__pass:nth-of-type(1) { 
            grid-area: premium;
            background: linear-gradient($green 0%, white 100%); 
            @media #{$media-mobile} { padding-top: 6rem; }
        }
        &__pass:nth-of-type(2) { 
            grid-area: festival;
            background: linear-gradient(white 0%, $blue 100%); 
        }
        &__pass:nth-of-type(3) { 
            grid-area: day;
            background: linear-gradient($pink 0%, white 100%); 
        }
        &__pass:nth-of-type(4) { 
            grid-area: virtual;
            background: linear-gradient(white 0%, $yellow 100%); 
        }

        &__pass {
            display: flex;
            flex-direction: column;
            min-height: 27vw;

            @media #{$media-mobile} { padding: 3.8rem; }

            .h3, &__price {
                font-size: 3.2rem;
                @include ls;
                line-height: 1;
            }
            .h3 {
                width: 10rem;
                font-weight: bold;
                margin-bottom: 0.04em;
            }
            &__price {
                margin-bottom: 1em;
            }
            &__description {
                flex-grow: 1;
            }
        }
    }

    .s2 {
        
        &__testimonial {
            width: calc(75vw - 7.5px);
            height: auto;
            padding: 8rem $pad-h;
               
            background-color: $orange;
            color: white;
            &:nth-of-type(2n) {
                background-color: black;
            }

            // inner disposition
            display: grid;
            grid-template-columns: 73% 27%;

            &__quote {
                font-size: 3.2rem;
                @include ls;
                line-height: 36/32;
            }
            &__author {
                text-align: right;
                margin-left: $pad-h;
                align-self: end;

                font-size: 1.8rem;
                line-height: 1;
                @include ls;
                @include fw-medium;
            }

            @media #{$media-mobile} {
                grid-template-columns: 1fr;
                padding: 4rem $pad-h-m;

                &__author {
                    margin-top: 6rem;
                }
            }
        }

        .swiper-wrapper {
            height: fit-content;
            cursor: grab;
        }

    }

    // FEATURES TABLE

    .s3 {
        display: grid;
        grid-template-columns: 25% 1fr;
        @media #{$media-tablet} { grid-template-columns: 30% 1fr; }
        @media #{$media-mobile} { grid-template-columns: 1fr; }

        &__left {

            &__wrapper {
                position: sticky;
                top: calc(#{$header-h} - 2px);

                @media #{$media-mobile} { 
                    top: calc(#{$header-h-m} + #{$mobilebar-h-m} - 2px); 
                }
            }

            .h3 {
                font-size: 5rem;
                @include ls;
                padding-bottom: 0em;
                line-height: 1;

                @media #{$media-mobile} { 
                    padding-top: 1rem;
                    max-width: 10rem; 
                }
            }
            .button {
                transition: background-color 0.2s ease,
                            color 0.2s ease;

                @media #{$media-mobile} { margin: 1rem 0 0 0; }
            }

            .button:hover, 
            .button:active, 
            .button:focus {
                background-color: white;
                color: black;
            }

        }
        &__right {
            .grid-passes {
                &__row {
                    display: grid;
                    grid-template-columns: 28% 1fr;
                    align-items: center;

                    border-bottom: 1px solid black;
                    gap: 0 5vw;

                    .rest {
                        display: grid;
                        grid-template-columns: repeat(4,1fr);
                        align-items: center;
                        gap: 0 5vw;
                        padding-right: 3rem;
                    }

                    &.head {
                        position: sticky;
                        top: calc(#{$header-h} - 2px);

                        background-color: white;

                        .rest {
                            width: 100%;
    
                            > * {
                                padding: 1.5rem 0;
                                width: 5rem;
                                font-size: 2.4rem;
                                line-height: 1;
                                @include ls;
                                font-weight: bold;

                                @media #{$media-mobile} {
                                    font-size: 2.1rem;
                                    @include ls;
                                }

                                @media #{$media-mobile-sm} {
                                    font-size: 1.8rem;
                                    @include ls;
                                }
                            }
                        }
                    }

                    &.feat {
                        .first {
                            line-height: 2rem;
                            padding: 1.5rem 0;
                        }

                        .check-slot {
                            background-repeat: no-repeat;
                            background-position: 2rem; // alignment acc. to design
                            background-size: 2.7rem;
                            height: 2.7rem;

                            &:nth-child(1).tick { background-image: url('../img/checks/green.svg'); }
                            &:nth-child(2).tick { background-image: url('../img/checks/blue.svg'); }
                            &:nth-child(3).tick { background-image: url('../img/checks/pink.svg'); }
                            &:nth-child(4).tick { background-image: url('../img/checks/yellow.svg'); }    
                        }
                    }

                    @media #{$media-tablet} { 
                        .rest {
                            padding-right: 0rem;
                        }

                        &.head {
                            display: block;
                            .first { display: none; }    
                            top: calc(#{$header-h-m} + #{$mobilebar-h-m});
                        }

                        &.feat {
                            grid-template-columns: 1fr;
                            .first { padding-bottom: 1rem; }
                            .rest { 
                                padding-bottom: 1.5rem; 

                                .check-slot {
                                    height: 2.8rem;
                                    background-size: 2.8rem;
                                }
                            }
                        }
                    }
                }

                &__row:last-of-type {
                    padding-bottom: 13rem;
                    @media #{$media-tablet} { padding-bottom: 0; }
                }
            }
        }
    }

    // Marquee 

    .s4 {

        @media #{$media-mobile} { display: none; }

        .marquee-parent {
            height: 13.4rem;
            .marquee {
                font-size: 6rem;
                @include ls;

                img {
                    margin: 0 3rem;
                }
            }
        }
    }

    // other passes

    .s5 {
        display: grid;
        grid-template-columns: 1fr 1fr;

        @media #{$media-mobile} {grid-template-columns: 1fr; }

        &__student { background: linear-gradient(rgba(255,83,0,0.7) 0%, white 90%); }
        &__group { background: linear-gradient(white 10%, rgba(255,83,0,0.7) 100%); }

        &__student, &__group {
            display: flex;
            flex-direction: column;
            min-height: 18vw;

            .h4 {
                line-height: 1;
                font-weight: bold;
                padding-bottom: 2.5rem;
                margin-top: 1.4rem;

                @media #{$media-mobile} {
                    font-size: 3.2rem;
                    @include ls;
                }

            }

            .description {
                line-height: 2rem;
                p {
                    margin-bottom: 0.8em;
                }
                flex-grow: 1;
            }

            .button {
                width: 14.4rem;
            }
        }

    }
}