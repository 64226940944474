.template-post-event-session {
    color: white;
    background-color: black;

    // back
    .s0 {

        padding-bottom: 0;
        margin-top: 1rem;

        &__back {
            font-size: 1.6rem;
            @include ls-50;
    
            @media #{$media-mobile} { 
                font-size: 1.4rem;
                @include ls-50;
            }
    
            svg {
                height: 0.8em;
                padding-top: 0.05em;

                // ui
                path {
                    transition: stroke 0.3s ease;
                }
            }

            transition: color 0.3s ease;
            &:hover, &:focus, &:active {
                color: white!important;

                svg {
                    path {
                        stroke: white;
                    }
                }
            }
        }
    }

    // top part
    .s1 {
        &__grid {

            display: grid;
            grid-template-columns: 2fr 1fr;
            gap: 1.5rem;


            &__video {
                cursor: pointer;
                border-radius: 1rem;
                overflow: hidden;

                transition: transform 0.4s ease;

                &:hover {
                    transform: scale(1.01)!important;
                }
            }

            &__text {

                .h3 {
                    font-size: 3.6rem;
                    @include ls-50;
                    font-weight: 500;
                    line-height: 1.1;
                    padding-bottom: 0.33em;

                    @media #{$media-mobile} { 
                        font-size: 3rem;
                        @include ls-50;
                    }
                }

                .rrss {
                    display: flex;
                    align-items: center;

                    font-size: 1.6rem;
                    @include ls-50;
                    margin-bottom: 1em;

                    span {
                        padding-top: 0.2rem;
                        margin-right: 0.5rem;
                    }

                    .a2a {
                        &_kit svg {
                            height: 1.7rem;
                            margin: 0.25rem;

                            path {
                                transition: fill 0.3s ease;
                            }

                            &:hover, &:active, &:focus {
                                path {
                                    fill: $orange;
                                }
                            }
                        }
                    }

                    @media #{$media-mobile} { 

                        font-size: 1.4rem;
                        @include ls-50;

                        .a2a {
                            &_kit svg {
                                height: 2.3rem;
                                margin-left: 0.8rem;
                            }
                        }
                    }
                }

                .h6,
                .description,
                .sponsors {
                    font-size: 1.8rem;
                    letter-spacing: -0.03em;
                    line-height: 1.2;

                    @media #{$media-mobile} { 
                        font-size: 1.5rem;
                        letter-spacing: -0.01em;
                    }
                }

                .sponsors {
                    margin-top: 3rem;
                    @include a-to-orange;
                    a {
                        text-decoration: underline;
                    }
                }
            }
        }
    }

    // details
    .s2 {
        &__grid {

            display: grid;
            grid-template-columns: 2fr 1fr;
            gap: 1.5rem;
            
            .h6 {
                border-bottom: 1px solid white;
                letter-spacing: -0.01em;
            }

            .detail {
                &__list {
                    padding-top: 1.4rem;
                    margin-bottom: 3.3rem;

                    &__item {
                        padding-bottom: 1.1em;
                        padding-right: 2em;
                    }
                }

                &--speakers {
                    .detail__list {
                        display: flex;
                        flex-wrap: wrap;

                        &__item {
                            
                            width: 50%;
                            
                            a {
                                text-decoration: none;
                            }

                            .name {
                                font-weight: 500;
                                font-size: inherit;
                                padding-bottom: 0.15em;
                                text-decoration: underline;
                            }
                        }
                    }
                }

                &--topics, &--format {
                    .detail__list__item {
                        padding-bottom: 0.25em;
                    }
                }
            }
        }
    }

    // related
    .s3 {
        margin-bottom: 2rem;

        &__title {
            font-size: 2.4rem;
            @include ls-50;

            @media #{$media-mobile} { 
                font-size: 2rem;
                @include ls-50;
            }

            padding-bottom: 0.2em;
            margin-bottom: 2.5rem;
            border-bottom: 1px solid white;
        }
    }

    // loadmore
    .loadmore-banner {
        display: block;
    }

    // responsive
    
    @media #{$media-tablet} { 

        .s1 {
            &__grid {
                grid-template-columns: 1fr;
            }
        }
    }

    @media #{$media-mobile} { 
        .s2 {
            &__grid {
                grid-template-columns: 1fr;
                gap: 0;
            }
        }
    }

    @media #{$media-mobile-sm} { 
        .s2 {
            &__grid {
                .detail {
                    &--speakers {
                        .detail__list {
                            display: block;
                        }
                    }
                }
            }
        }
    }
}