// Spacing

@mixin container {
    padding: $pad-v calc(#{$pad-h} - 10px) $pad-v $pad-h;

    @media #{$media-tablet} {
        padding: $pad-v-t $pad-h-t;
    }

    @media #{$media-mobile} {
        padding: $pad-v-m $pad-h-m;
    }
}

@mixin sym-container {
    padding: $pad-v $pad-h $pad-v $pad-h;

    @media #{$media-tablet} {
        padding: $pad-v-t $pad-h-t;
    }

    @media #{$media-mobile} {
        padding: $pad-v-m $pad-h-m;
    }
}

@mixin margin-container {
    margin: $pad-v calc(#{$pad-h} - 10px) $pad-v $pad-h;

    @media #{$media-tablet} {
        margin: $pad-v-t $pad-h-t;
    }

    @media #{$media-mobile} {
        margin: $pad-v-m $pad-h-m;
    }
}

@mixin fill-space { 
    min-height: $fill-h;

    @media #{$media-tablet} {
        min-height: $fill-h-m;
    }

    @media #{$media-mobile} {
        min-height: fit-content;
    }
}

@mixin pad-top {
    padding-top: $pad-v;
    @media #{$media-tablet} { padding-top: $pad-v-t; }
    @media #{$media-mobile} { padding-top: $pad-v-m; }
}

@mixin pad-bottom {
    padding-bottom: $pad-v;
    @media #{$media-tablet} { padding-bottom: $pad-v-t; }
    @media #{$media-mobile} { padding-bottom: $pad-v-m; }
}

@mixin pad-left {
    padding-left: $pad-h;
    @media #{$media-tablet} { padding-left: $pad-h-t; }
    @media #{$media-mobile} { padding-left: $pad-h-m; }
}

@mixin pad-right {
    padding-right: calc(#{$pad-h} - 10px);
    @media #{$media-tablet} { padding-right: $pad-h-t; }
    @media #{$media-mobile} { padding-right: $pad-h-m; }
}


// Text format

@mixin ls { letter-spacing: -0.06em; } //default letter spacing
@mixin ls-50 { letter-spacing: -0.05em; }

@mixin text-small { 
    font-size: 1.8rem;
    line-height: 2rem;
    letter-spacing: 0;

    @media #{$media-tablet} {
        font-size: 1.5rem;
    }
}

@mixin fw-medium { font-weight: 500;}
@mixin fw-sbold { font-weight: 600;}


// Links

@mixin a-to-orange {
    a:hover, a:focus, a:active {
        transition: color 0.2s ease;
        color: $orange;
    }
}


// default text sizes

@mixin defaulttext{
    p, li {
        @include ls;
    }
    h1 {
        font-size: 9rem;
        @include ls-50;
        line-height: 7rem;
        padding-bottom: 8rem;
        @media #{$media-laptop} {
            font-size: 7.5rem;
            @include ls-50;
            line-height: 6rem;
            padding-bottom: 7rem;
        }
        @media #{$media-tablet} {
            font-size: 6.5rem;
            @include ls-50;
            line-height: 5rem;
            padding-bottom: 5rem;
        }
    }
    h2 {
        font-size: 4rem;
        @include ls-50;
        padding-bottom: 2.8rem;

        @include fw-medium;  
        line-height: 1;

        @media #{$media-tablet} {
            font-size: 3.2rem;
            @include ls-50;
            padding-bottom: 2.5rem;
        }
    }
    h3 {
        font-size: 3.2rem;
        @include ls;
        line-height: 1;
        font-weight: bold;
        padding-bottom: 2.5rem;

        @media #{$media-tablet} {
            font-size: 2.4rem;
            @include ls;
            padding-bottom: 2.2rem;
        }
    }
    h4 {
        font-size: 2.4rem;
        @include ls;
        line-height: 1;
        font-weight: bold;
        padding-bottom: 2.3rem;

        @media #{$media-tablet} {
            font-size: 2.1rem;
            @include ls;
            padding-bottom: 2rem;
        }
    }
    h5 {
        font-size: 2rem;
        @include ls;
        line-height: 2.4rem;
        font-weight: bold;
        padding-bottom: 2.2rem;

        @media #{$media-tablet} {
            font-size: 1.95rem;
            @include ls;
            padding-bottom: 2rem;
        }
    }
    h6 {
        font-size: 1.4rem;
        letter-spacing: 0;
        line-height: 1.6rem;
        padding-bottom: 2.1rem;

        @media #{$media-tablet} {
            padding-bottom: 2rem;
        }
    }
}

.defaulttext{
    @include defaulttext;
}

// richtext fields


@mixin richtext {

    p {
        line-height: 1.3em;
        padding-bottom: 1em;
        @include ls;
    }
    ul {
        padding-left: 1.5em;
        padding-bottom: 1.5em;

        li {
            position: relative;
            padding-bottom: 1em;
            @include ls;
        }

        li:before {
            position: absolute;
            display: block;
            content:"•";
            font-size: 1.6em;
            top: -0.16em;
            left: -1.5em;
        }
    }
    a {
        display: inline;
        color: $orange;
        transition: text-decoration 0.2s ease;
    }
    a:hover, a:active, a:focus {
        text-decoration: underline;
    }
}

.richtext {
    @include richtext;
}

@mixin richtext-small {
    font-size: 1.85rem;
    line-height: 36/30;
    @include ls;
    p {
        padding-bottom: 1em;
    }
    ul {
        padding-bottom: 0.61em;
        margin-top: -0.1em;
        li {
            padding-bottom: 0.4em;
            list-style-type: none!important;
        }
        li:before {
            font-size: 0.8em;
            top: 0.2em;
            left: -1.1em;
        }
    }
}

.richtext-small {
    @include richtext-small;
}