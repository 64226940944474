.loader {
    position: fixed;
    z-index: -1;
    display: flex;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    align-items: center;
    justify-content: center;
    font-size: 4.5rem;
    @include ls;
    @include fw-medium;

    opacity: 0;
    transition: opacity 0.5s ease;

    &__text {
        // animation-duration: 1.5s;
        // animation-iteration-count: infinite;
        // animation-timing-function: ease;
        // animation-direction: alternate;
        // animation-delay: 0.5s;
        opacity: 0.2;
        color: white;

        // @keyframes blink {     
        //     100% {
        //         opacity: 1;
        //     }
        // }
    }
}