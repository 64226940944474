.header {

    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 100;

    background-color: white;
    @include a-to-orange;

    overflow: visible!important;
    
    &__mobilebar {
        font-size: 1.6rem;
        @include ls;
        @include fw-medium;

        z-index: 100;
        position: relative;

        height: $mobilebar-h-m;
        line-height: $mobilebar-h-m;
        
        .container {
            padding-top: 0;
            padding-bottom: 0;
        }

        display: none;
        @media #{$media-tablet} {
            display: block;
        }

        transition: background-color 0.5s ease, color 0.5s ease;
        &.expanded {
            background-color: white;
            color: black;
        }

        pointer-events: none;
        cursor: default;
        color: white!important;
    }

    &__wrap {

        display: -webkit-flex;
        display: flex;
        flex-direction: row;
        -webkit-justify-content: space-between;
        justify-content: space-between;
        align-items: center;
        width: calc(100vw - 10px); // make mobilemenu display animation fluid
        height: $header-h;
        background-color: #fff;
        position: relative;
        z-index: 100;

        @media #{$media-tablet} {
            height: $header-h-m;
        }

        &__logo {
            @include pad-left;
        }

        &__menu {
            display: flex;
            align-items: center;
        }
    }

    &__xtrabar {
        display: none;
        // font-size: 1.6rem;
        // @include ls;
        // font-weight: normal;

        height: $mobilebar-h-m;
        line-height: $mobilebar-h-m;
        
        .container {
            padding-top: 0;
            padding-bottom: 0;
        }

        &__text { 
            margin-right: 0.7em;
            display: inline;
        }

        a { display: inline; }

    }
}