.video-full {
    video {  
        width: 100%;
        object-fit: cover;
    }
}

.video--hi {
    display: block;

    @media #{$media-tablet} {
        display: none;
    }
}

.video--lo {
    display: none;

    @media #{$media-tablet} {
        display: block;
    }
}


.js-vimeo-loader {
    cursor: pointer;
}


.vimeo-popup {

    .content {
        width: 70%;
        height: 70%;
        @media #{$media-mobile} { width: 100%; }
        display: flex;
        align-items: center;
        justify-content: center;

        iframe {
            width: 100%;
            height: 100%;
            position: relative;
        }


        // video::before {
        //     content:'×';
        //     position: absolute;
        //     width: 4rem;
        //     height: 4rem;
        //     top: -2rem;
        //     right: -2rem;
        //     font-size: 3rem;
        //     line-height: 1;
        //     color: white;
        //     cursor: pointer;
        //     opacity: 1;
        
        //     // animation: appear 1.5s ease 0.5s 1;
        //     // animation-fill-mode: forwards;
        // }
    }

    #popup-close {
        top: 2rem;
        right: 3rem;

        @media #{$media-tablet} { 
            right: 2rem;
        }
    }
}
