.template-single-speaker {
    .s0 {
        position: relative;
        padding-top: 7.4rem;
        padding-bottom: 13.8rem;

        &__back {
            margin-bottom: 6rem;
        }

        &__speaker {
            display: grid;
            grid-template-columns: auto 1fr;

            &__image {
                // max-width: 26vw;
                // width: 39.5rem;
                // margin-right: 6.3vw;
                max-width: 270px;
                margin-right: 5vw;

                img {
                    border-radius: 1rem;
                    width: 100%;
                }
            }

            &__text {
                display: grid;
                align-content: space-between;

                &__header {
                    margin-bottom: 4rem;

                    .name {
                        font-weight: bold;
                        margin-bottom: 0.1em;
                    }   
                    .lines {
                        max-width: 40vw;
                    }

                    button:hover, button:focus, button:active{
                        color: $blue!important;
                        width: fit-content;
                    }
                    #a2a {
                        max-width: 0%;
                        opacity: 0;
                        width: fit-content;
                        transition: max-width 5.4s cubic-bezier(0.075, 0.82, 0.165, 1),
                                    opacity 0.4s ease;
                        overflow: hidden;

                        .a2a_kit {
                            width: 30rem;
                        }

                        &.show {
                            max-width: 100%;
                            opacity: 1;
                        }
                    }
                }

                &__wrap {
                    display: flex;

                    @media #{$media-mobile} { display: block; }

                    &__description {
                        white-space: pre-wrap;
                        margin: 0;
                        font-family: inherit;
                    }
                    &__links {
                        margin: 0 9vw 0 4.5vw;
                        a { 
                            font-weight: inherit;
                            text-decoration: none;
                            line-height: 2rem;
                        }
                    }
                }
            }
        }
    }

    @media #{$media-tablet} {
        .s0 {
            &__speaker {
                &__text {
                    &__header {
                        .h4 {
                            font-size: 3.2rem;
                            @include ls;
                            line-height: 1;
                        }
                    }
                }
            }
        }
    }

    @media #{$media-mobile} {
        .s0 {
            padding-top: 4rem;
            padding-bottom: 7rem;
    
            &__back {
                margin-bottom: 1rem;
            }
    
            &__speaker {
                grid-template-columns: 1fr; 

                &__image {
                    max-width: 100%;
                    width: unset;
                    margin-right: 0;
                    margin-bottom: 1.2rem;
                }
    
                &__text {
                    display: block;
    
                    &__header {
                        margin-bottom: 2rem;

                        .lines {
                            margin-bottom: 0.8rem;
                            max-width: unset;
                        }
                        button {
                            margin-right: 1rem;
                        }
                        #a2a {
                            display: inline-block;
                            vertical-align: middle;
                            padding-bottom: 0.4rem;

                            transition: max-width 1s cubic-bezier(0.075, 0.82, 0.165, 1),
                            opacity 0.4s ease;
                        }
                    }

                    &__wrap {
                        display: block;
                        
                        &__links {
                            margin: 2.5rem 0 0 0;
                            a { 
                                font-weight: inherit;
                                text-decoration: none;
                                line-height: 2rem;
                            }
                        }
                    }
                }
            }
        }
    }

    @media #{$media-mobile-sm} {
        .s0 {&__speaker {&__text {&__header {#a2a {display: block;}}}}}
    }

    .marquee-parent {
        display: none;
        @media #{$media-mobile} { display: flex; }
    }
}