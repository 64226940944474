.template-single-legal {

    .s0 {
        max-width: 70rem;
        margin: 0 auto;
        padding-top: 7rem;
        padding-bottom: 16rem;

        @media #{$media-tablet} { 
            padding-top: 6rem;
            padding-bottom: 8rem;
        }
    }
}