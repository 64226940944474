.template-page-ple {

    .h3 {
        font-size: 4.5rem;
        @include ls-50;
        line-height: 1;
        font-weight: 500;

        padding-bottom: 0.4em;

        @media #{$media-tablet} {
            font-size: 3.2rem;
            @include ls-50;
        }
    }

    // cover

    .s0 {
        position: relative;
        overflow: hidden;
        color: white;


        strong, b, a {
            color: $green!important;
        }

        a:hover, a:focus, a:active {
            color: white!important;
        }

        video {
            position: absolute;
            border-bottom: 1px solid black; //bug in iphone
            z-index: -1;
            height: 100%;
        }

        &__title {
            min-height: 18rem;

            @media #{$media-mobile} {
                min-height: 11rem;
            }

            h1 {
                font-size: 5rem;
                letter-spacing: -0.04em;
                font-weight: normal;
                line-height: 1;
                padding-bottom: 0;
        
                @media #{$media-tablet} {
                    font-size: 4rem;
                    @include ls-50;
                }
            }
        }
        &__wrap {
            display: grid;
            grid-template-columns: 1fr 1fr;
            margin-bottom: 4rem;

            @media #{$media-mobile} {
                grid-template-columns: 1fr;
            }
        }

        &__titlebig {
            padding-right: 5.5vw;

            font-size: clamp(6rem,5vw,10rem);
            @include ls-50;
            line-height: 0.9;
            font-weight: normal;

            @media #{$media-tablet} {
                @include ls-50;
            }

            @media #{$media-mobile} {
                padding-bottom: 1rem;
            }
        }
        &__text{
            padding-top: clamp(1rem,1.6vw,3rem);

            font-size: 2.4rem;
            line-height: 2.6rem;
            letter-spacing: -0.025em;

            @media #{$media-tablet} {
                font-size: 1.8rem;
                line-height: 1.9rem;
            }
            @media #{$media-mobile} {
                margin-bottom: 0;
            }
        }
        &__small{
            max-width: 44%;
            width: 26rem;
            strong, b {
                font-weight: normal;
            }
            font-size: 2.4rem;
            line-height: 2.6rem;
            letter-spacing: -0.04em;

            .alt {
                padding-top: 2em;

                line-height: 1;
                letter-spacing: -0.06em;
                font-weight: 500;
                width: 20rem;

                @media #{$media-mobile} {
                    padding-top: 1.5em;
                }
            }

            @media #{$media-tablet} {
                font-size: 1.8rem;
                line-height: 1;
            }
        }
        &__form{

            @media #{$media-mobile} {
                margin-top: 4rem;
            }

            form {
                position: relative;

                .hs_firstname, .hs_lastname, .hs_email {
                    label {
                        display: none;
                    }
                }
                .hs_firstname, .hs_lastname {
                    display: inline-block;
                    width: calc(50% - 0.9rem);
                }
                .hs_firstname {
                    margin-right: 1.8rem;
                }

                input {
                    padding: 0.65em 0;
                    font-size: 1.6rem;
                    letter-spacing: -0.025em;

                    @media #{$media-tablet} {
                        font-size: 1.4rem;
                    }
                }
                input[type="text"], input[type="email"] {
                    border-bottom: 1px solid white;
                    width: 100%;

                    &::-webkit-input-placeholder { color: white; }
                    &::-ms-input-placeholde { color: white; }
                    &:-ms-input-placeholder { color: white; }
                }
                input[type="submit"] {
                    background-color: white;
                    border: 1px solid white;
                    color: black;
                    border-radius: 0.5rem;
                    font-weight: bold;

                    min-width: 8.2em;

                    position: absolute;
                    bottom: 0;
                    right: 0;

                    // interaction
                    transition: background-color 0.1s ease-in-out, color 0.1s ease-in-out;
                    &:hover, &:focus, &:active { 
                        background-color: transparent;
                        color: white;
                    }
                }
                .legal-consent-container {
                    margin-top: 2.5rem;

                    font-size: 1.2rem;
                    line-height: 1.4rem;
                    letter-spacing: -0.025em;

                    .hs-richtext:last-of-type {
                        max-width: calc(92% - 13rem);

                        @media #{$media-tablet} {
                            max-width: calc(92% - 11rem);
                        }
                    }
                }

                // custom checkboxes

                [type="checkbox"] {
                    opacity: 0;
                    position: absolute;
                }

                [type="checkbox"] + span {
                    padding-left: 0!important;
                    display: flex;
                    align-items: center;
                }

                [type="checkbox"] + span::before {
                    content: '';
                    min-height: 12px;
                    min-width: 12px;
                    margin-right: 10px;
                    border: 1px solid white;
                    display: inline-block;
                    margin-bottom: 2px;
                }

                [type="checkbox"]:checked + span::before {
                    background: white;
                    box-shadow: inset 0 0 0 2px white;
                }
                
                .hs-form-booleancheckbox {
                    margin: 1em 0;
                }
                .legal-consent-container .hs-form-booleancheckbox-display>span {
                    margin-left: 0;
                }
            }
        }
    }

    // green

    .s1 {
        background-color: $green;
        &__top {
            display: grid;
            grid-template-columns: 1fr 33rem;
            padding-top: 6.5rem;

            @media #{$media-mobile} {
                grid-template-columns: 1fr;
            }

            &__text{
                font-size: 3.2rem;
                letter-spacing: -0.025em;
                line-height: calc(36/32);

                @media #{$media-tablet} {
                    font-size: 2.4rem;
                    letter-spacing: -0.025em;
                }
                                
                @media #{$media-mobile} {
                    margin-bottom: 3rem;
                }

                a:hover, a:focus, a:active {
                    color: white!important;
                }
            }
            &__link{
                max-width: 22rem;
                text-transform: uppercase;
                justify-self: end;

                font-size: 1.6rem;
                letter-spacing: -0.025em;
                line-height: 1;
                                                
                @media #{$media-mobile} {
                    margin: 1.5rem 0;
                    justify-self: unset;
                }

                // interaction
                a {
                    transition: color 0.2s ease;
                }
                a:hover, a:active, a:focus {
                    color: white;
                }

                .button {
                    background-color: black;
                    border: 1px solid black;
                    color: $green!important;
                    border-radius: 0.5rem;
                    font-weight: bold;
                    font-size: 1.15em;
        
                    min-width: 8.2em;
                    
                    // interaction
                    transition: background-color 0.1s ease-in-out, color 0.1s ease-in-out;
                    &:hover, &:focus, &:active { 
                        background-color: transparent;
                        color: white!important;
                        border-color: white;
                    }
                }
        
            }
        }
        &__activities {
            background-image: linear-gradient(to bottom, $green 0, white 15rem);

            .h4, .h5{
                font-weight: 500;
            }

            &__grid {
                display: grid;
                grid-template-columns: 1fr 1fr;
                gap: 2rem;

                @media #{$media-mobile} {
                    grid-template-columns: 1fr;
                }

                &__left {
                    border-radius: 1rem;

                    .content {
                        border-radius: 1rem;
                        background-image: linear-gradient(to bottom, transparent 30%, $green 80%);
                        height: 100%;
                        display: flex;
                        justify-content: flex-end;
                        flex-direction: column;
                        padding: 3rem;

                        .h4 {
                            line-height: 1;
                            padding-bottom: 0;
                        }

                        .text{
                            font-size: 1.8rem;
                            letter-spacing: -0.06em;
                            line-height: 2rem;

                            *:last-of-type {
                                padding-bottom: 0;
                            }

                            a:hover, a:focus, a:active {
                                color: white!important;
                            }
                        }
                    }

                    @media #{$media-mobile} {
                       min-height: 94vw;

                       .h4{
                            margin-top: 12rem;
                        }
                    }
                }
                &__right {

                    .content {
                        height: 46vw;
                        max-height: 100vh;
                        overflow-y: scroll;
                        padding-bottom: 14rem;

                        @media #{$media-mobile} {
                            overflow-y: auto;
                            height: unset;
                            max-height: unset;
                            padding-bottom: 6rem;
                        }
                    }

                    .h4 {
                        line-height: 1;
                        padding-bottom: 0.7em;
                    }

                    .workshops {
                        &__item {
                            border-bottom: 1px solid #E0E0E0;
                            margin-bottom: 2rem;
                            padding-bottom: 2rem;
                            &:last-of-type { 
                                border-width: 0;
                                margin-bottom: 0;
                                padding-bottom: 0;
                            }
                            
                            display: grid;
                            grid-template-columns: 26% 1fr;
                            gap: 2rem;

                            img {
                                border-radius: 1rem;
                            }

                            .text {
                                align-self: center;

                                .h5 {
                                    padding-bottom: 0.1em;
                                }

                                .description {
                                    font-size: 1.8rem;
                                    letter-spacing: -0.06em;
                                    line-height: 2rem;
                                
                                    *:last-of-type { 
                                        padding-bottom: 0;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    // map

    .s2 {
        overflow: hidden;
        margin-bottom: 1rem;
        @include pad-top;

        &__title {
            @include pad-left;
        }
        
        &__wrap {
            display: grid;
            grid-template-columns: auto minmax(350px, 32vw);

            @media #{$media-tablet} {
                display: block;
            }

            &__map {
                @include pad-left;
                @include pad-right;
                @include pad-bottom;

                position: relative;
            }

            &__texts {
                position: relative;
                padding-left: 8vw;
                @include pad-right;
                @include pad-bottom;
                max-width: 100%;

                @media #{$media-tablet} {
                    @include container;
                    padding-top: 0!important;
                }

                .mapinfo {
                    background-color: transparent;
                    width: 100%;
                    height: min-content;
                    font-size: 1.8rem;
                    letter-spacing: -0.04em;

                    &__flex {
                        display: flex;
                        align-items: center;
                        margin-bottom: 2rem;

                        img {
                            margin-right: 1rem;
                            width: 27px;
                        }

                        .h5 {
                            padding-bottom: 0;
                            font-weight: normal;
                            text-decoration: underline;
                            font-size: inherit;
                        }
                    }

                    @media #{$media-tablet} {
                        border-radius: 10px;
                        transition: background-color 0.3s ease;
                        width: fit-content;
                        max-width: 300px;

                        padding: 2rem;

                        font-size: 1.5rem;
                        letter-spacing: -0.04em;
                    }
                }

                .swiper-wrapper {
                    height: auto;
                }

                .swiper-slide {
                    margin-right: $pad-h-t;
                    &:last-of-type {
                        margin-right: 0;
                    }

                    .richtext a {
                        color: inherit;
                    }
    
                    // ui
                    cursor: default;
                    opacity: 0;
                    transition: all 1s ease;
                    &.active {
                        opacity: 1;
                        @media #{$media-tablet} {
                            background-color: $orange;
                        }
                    }

                    // resp
                    @media #{$media-tablet} {
                        margin-right: $pad-h-m;
                        opacity: 1;
                    }
                }
            }
        }
    }
    
    // agenda

    .s3 {
        &__title {
            @include pad-left;
            @include pad-top;
        }
    }

    // bottom

    .s4 {
        &__grid {
            display: grid;
            grid-template-columns: 1fr 1fr;

            @media #{$media-mobile} {
                grid-template-columns: 1fr;
            }

            &__block {
                height: 50vw;
                display: grid;
                align-content: end;
                .content {
                    @include container;
                    padding-top: 22%;

                    &--left {
                        background-image: linear-gradient(to top, $blue 34%, transparent 100%);
                    }
                    &--right {
                        background-image: linear-gradient(to top, $orange 34%, transparent 100%);
                    }
                    .h4 {
                        font-size: 3.2rem;
                        letter-spacing: -0.05em;
                        line-height: 1;
                        font-weight: 500;
                        @media #{$media-tablet} {
                            font-size: 2.4rem;
                            letter-spacing: -0.025em;
                        }
                    }

                    .text {
                        *:last-of-type { 
                            padding-bottom: 0;
                        }
                    }
                }

                @media #{$media-mobile} {
                    min-height: 100vw;
                }

                // interaction
                a {
                    transition: color 0.2s ease;
                }
                a:hover, a:active, a:focus {
                    color: white;
                }
            }
        }
    }

    .mobileregister {
        display: none !important;
    }
}