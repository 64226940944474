.template-speakers {

    .speakers-section {
        .grid-speakers {
            &__card {
                
                &.unloaded {
                    display: none;
                }
                opacity: 0;
            }
        }

        &__bottom {
            #loadmore {
                cursor: pointer;

                &:hover, &:active, &:focus {
                    color: $orange;
                    transition: color 0.2s ease;
                }
            }

        }
    }

    .marquee-parent {
        display: none;
        @media #{$media-mobile} { display: flex; }
    }

}