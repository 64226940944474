.v-div {

    position: relative;
    @include fill-space;

    display: grid;
    grid-template-columns: 33% 1fr;
    grid-template-areas: 'text picture';

    &__left {
        grid-area: text;

        height: fit-content;
        @include fill-space;
        @media #{$media-tablet} {
            min-height: fit-content;
        }

        display: flex;
        flex-direction: column;
        flex-grow: 0;

        .h4 {
            line-height: 3.4rem;
        }

        &__title {
            flex-grow: 1;
            margin-bottom: 6rem;
        }
    }

    &__right {
        grid-area: picture;
        height: 100%;
        position: relative;
        overflow: hidden;
    }

    @media #{$media-tablet} {
        grid-template-columns: 1fr;
        grid-template-areas: 'picture' 'text';

        &__left {

            &__title {
                margin-bottom: 5rem;
            }

            .h4 {
                font-size: 3.2rem;
                @include ls;
                padding-bottom: 4.5rem;
            }

            padding-bottom: 5.3rem;
        }

        &__right {
            position: relative;
            height: 100vw;
            // margin-bottom: 1rem;
        }  
    }
}