#edificio_web {
    .map-layer{
        &.cls-3, .cls-3, &.cls-4, .cls-4 {
            fill:#FF5300;
            fill-opacity: 0;
            transition: fill-opacity 0.3s ease;
        }
        cursor: pointer;
    }
    .loaded{ 
        &.cls-3, .cls-3, &.cls-4, .cls-4 {
            fill-opacity: 0.2;
        }
    }
    .active{ 
        &.cls-3, .cls-3, &.cls-4, .cls-4 {
            fill-opacity: 1;
        }
    }
}
