

@font-face {
    font-family: 'ABC Favorit';
    src: url('../fonts/ABCFavorit-BoldItalic.eot');
    src: url('../fonts/ABCFavorit-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/ABCFavorit-BoldItalic.woff2') format('woff2'),
        url('../fonts/ABCFavorit-BoldItalic.woff') format('woff'),
        url('../fonts/ABCFavorit-BoldItalic.ttf') format('truetype'),
        url('../fonts/ABCFavorit-BoldItalic.svg#ABCFavorit-BoldItalic') format('svg');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'ABC Favorit';
    src: url('../fonts/ABCFavorit-Regular.eot');
    src: url('../fonts/ABCFavorit-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/ABCFavorit-Regular.woff2') format('woff2'),
        url('../fonts/ABCFavorit-Regular.woff') format('woff'),
        url('../fonts/ABCFavorit-Regular.ttf') format('truetype'),
        url('../fonts/ABCFavorit-Regular.svg#ABCFavorit-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'ABC Favorit';
    src: url('../fonts/ABCFavorit-MediumItalic.eot');
    src: url('../fonts/ABCFavorit-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/ABCFavorit-MediumItalic.woff2') format('woff2'),
        url('../fonts/ABCFavorit-MediumItalic.woff') format('woff'),
        url('../fonts/ABCFavorit-MediumItalic.ttf') format('truetype'),
        url('../fonts/ABCFavorit-MediumItalic.svg#ABCFavorit-MediumItalic') format('svg');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'ABC Favorit';
    src: url('../fonts/ABCFavorit-RegularItalic.eot');
    src: url('../fonts/ABCFavorit-RegularItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/ABCFavorit-RegularItalic.woff2') format('woff2'),
        url('../fonts/ABCFavorit-RegularItalic.woff') format('woff'),
        url('../fonts/ABCFavorit-RegularItalic.ttf') format('truetype'),
        url('../fonts/ABCFavorit-RegularItalic.svg#ABCFavorit-RegularItalic') format('svg');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'ABC Favorit';
    src: url('../fonts/ABCFavorit-Medium.eot');
    src: url('../fonts/ABCFavorit-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/ABCFavorit-Medium.woff2') format('woff2'),
        url('../fonts/ABCFavorit-Medium.woff') format('woff'),
        url('../fonts/ABCFavorit-Medium.ttf') format('truetype'),
        url('../fonts/ABCFavorit-Medium.svg#ABCFavorit-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'ABC Favorit';
    src: url('../fonts/ABCFavorit-Bold.eot');
    src: url('../fonts/ABCFavorit-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/ABCFavorit-Bold.woff2') format('woff2'),
        url('../fonts/ABCFavorit-Bold.woff') format('woff'),
        url('../fonts/ABCFavorit-Bold.ttf') format('truetype'),
        url('../fonts/ABCFavorit-Bold.svg#ABCFavorit-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}




@font-face {
    font-family: 'ABC Favorit Lining';
    src: url('../fonts/ABCFavoritLining-Medium.eot');
    src: url('../fonts/ABCFavoritLining-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/ABCFavoritLining-Medium.woff2') format('woff2'),
        url('../fonts/ABCFavoritLining-Medium.woff') format('woff'),
        url('../fonts/ABCFavoritLining-Medium.ttf') format('truetype'),
        url('../fonts/ABCFavoritLining-Medium.svg#ABCFavoritLining-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'ABC Favorit Lining';
    src: url('../fonts/ABCFavoritLining-Regular.eot');
    src: url('../fonts/ABCFavoritLining-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/ABCFavoritLining-Regular.woff2') format('woff2'),
        url('../fonts/ABCFavoritLining-Regular.woff') format('woff'),
        url('../fonts/ABCFavoritLining-Regular.ttf') format('truetype'),
        url('../fonts/ABCFavoritLining-Regular.svg#ABCFavoritLining-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'ABC Favorit Lining';
    src: url('../fonts/ABCFavoritLining-RegularItalic.eot');
    src: url('../fonts/ABCFavoritLining-RegularItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/ABCFavoritLining-RegularItalic.woff2') format('woff2'),
        url('../fonts/ABCFavoritLining-RegularItalic.woff') format('woff'),
        url('../fonts/ABCFavoritLining-RegularItalic.ttf') format('truetype'),
        url('../fonts/ABCFavoritLining-RegularItalic.svg#ABCFavoritLining-RegularItalic') format('svg');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'ABC Favorit Lining';
    src: url('../fonts/ABCFavoritLining-MediumItalic.eot');
    src: url('../fonts/ABCFavoritLining-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/ABCFavoritLining-MediumItalic.woff2') format('woff2'),
        url('../fonts/ABCFavoritLining-MediumItalic.woff') format('woff'),
        url('../fonts/ABCFavoritLining-MediumItalic.ttf') format('truetype'),
        url('../fonts/ABCFavoritLining-MediumItalic.svg#ABCFavoritLining-MediumItalic') format('svg');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

