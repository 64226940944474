// Media queries
$media-laptop: "only screen and (max-width : 1440px)";
$media-tablet: "only screen and (max-width : 1024px)";
$media-mobile: "only screen and (max-width : 768px)";
$media-mobile-sm: "only screen and (max-width : 480px)";

// Colors
$orange: #ff5300;
$green: #6EEBA7;
$gold: #A7A076;
$pink: #FAA5F0;
$blue: #66A9FF;
$yellow: #ECFF54;
$gray: rgb(237,237,237);
$graytext: #878787;
$graytext2: #707070;

// Fonts
$f: "ABC Favorit", Helvetica, Arial, sans-serif;
$fl: "ABC Favorit Lining", Helvetica, Arial, sans-serif;

// Font-sizes
// $fz-20: unquote("max(14px, 2rem)");
// $fz-25: unquote("max(17px, 2.5rem)");
// $fz-30: unquote("max(17px, 3rem)");
// $fz-40: unquote("max(20px, 4rem)");

// Spacing : default paddings
$pad-v: 3.2rem;
$pad-h: 4rem;
$pad-v-t: 3rem;
$pad-h-t: 2.5rem;
$pad-v-m: 3rem;
$pad-h-m: 2rem;

// Spacing : vertical
$header-h: 6.4rem;
$header-h-m: 5rem;
$mobilebar-h-m: 2.8rem;
$data-h: 22rem;

// Spacing : left by header to fill window
$fill-h: calc(100vh - #{$header-h});
$fill-h-m: calc(100vh - #{$header-h-m} -  #{$mobilebar-h-m});
// $fill-h: 100vh;
// $fill-h-m: 100vh;