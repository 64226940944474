.template-page-experience {

    .s0 {
        .h2 {
            padding-bottom: 2rem;
        }
        .h4 {
            padding-bottom: 1.1rem;
        }
        .button {
            margin: 0;
        }
    }

    // map

    .s1 {
        overflow: hidden;
        margin-bottom: 1rem;
        
        &__wrap {
            display: grid;
            grid-template-columns: auto minmax(350px, 32vw);

            @media #{$media-tablet} {
                display: block;
            }

            &__map {
                @include container;
                position: relative;
    
                &__title {
                    position: absolute;
                    z-index: 1;
                }
            }

            &__texts {
                position: relative;
                padding-left: 8vw;
                @include pad-right;
                @include pad-bottom;
                max-width: 100%;

                @media #{$media-tablet} {
                    @include container;
                    padding-top: 0!important;
                }

                .mapinfo {
                    background-color: transparent;
                    width: 100%;
                    height: min-content;
                    font-size: 1.8rem;
                    letter-spacing: -0.04em;

                    &__flex {
                        display: flex;
                        align-items: center;
                        margin-bottom: 2rem;

                        img {
                            margin-right: 1rem;
                            width: 27px;
                        }

                        .h5 {
                            padding-bottom: 0;
                            font-weight: normal;
                            text-decoration: underline;
                            font-size: inherit;
                        }
                    }

                    @media #{$media-tablet} {
                        border-radius: 10px;
                        transition: background-color 0.3s ease;
                        width: fit-content;
                        padding: 2rem;
                        max-width: 300px;

                        font-size: 1.5rem;
                        letter-spacing: -0.04em;
                    }
                }

                .swiper-wrapper {
                    height: auto;
                }

                .swiper-slide {
                    margin-right: $pad-h-t;
                    &:last-of-type {
                        margin-right: 0;
                    }

                    .richtext a {
                        color: inherit;
                    }
    
                    // ui
                    cursor: default;
                    opacity: 0;
                    transition: all 1s ease;
                    &.active {
                        opacity: 1;
                        @media #{$media-tablet} {
                            background-color: $orange;
                        }
                    }

                    // resp
                    @media #{$media-tablet} {
                        margin-right: $pad-h-m;
                        opacity: 1;
                    }
                }
            }
        }
    }
    
    // experience items

    .s2 {
        .verticalitem {
            height: 33.3rem;
            width: 100%;
            position: relative;

            &__text {
                position: absolute;
                z-index: 10;
                top: 0;
                left: 0;
                height: 100%;
                width: 66%;
                padding: 0 27vw 0 10vw;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;

                .h4 {
                    font-weight: 500;
                    @include ls-50;
                    line-height: 1;
                    padding-bottom: 1rem;
                }
                .text {
                    font-size: 1.8rem;
                    @include ls;
                    line-height: 2rem;

                    a:hover, a:focus, a:active{
                        color: white;
                    }
                }
            }
            &__video {
                height: 100%;
                width: 50%;
                margin: 0 0 0 auto;

                video {
                    opacity: 0;
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                    transition: opacity 0.5s ease;
                }
            }

            // colors
            &.even {
                .verticalitem {
                    background-color: black;
                    &__text {
                        background: linear-gradient(to left, black 77%, transparent 100%);
                        left: unset;
                        right: 0;
                        color: #fff;
                        padding: 0 10vw 0 27vw;

                        a:hover, a:focus, a:active{
                            color: $orange;
                        }
                    }
                    &__video {
                        margin: 0 auto 0 0;
                    }
                }
            }
            &:nth-child(1) {
                background-color: $orange;

                .verticalitem__text {
                    background: linear-gradient(to right, $orange 77%, transparent 100%);
                }
            }
            &:nth-child(3) {
                background-color: $blue;

                .verticalitem__text {
                    background: linear-gradient(to right, $blue 77%, transparent 100%);
                }
            }
            &:nth-child(5) {
                background-color: $green;

                .verticalitem__text {
                    background: linear-gradient(to right, $green 77%, transparent 100%);
                }
            }

            @media #{$media-laptop} {
                height: 36rem;
            }
            @media #{$media-tablet} {
                height: 40rem;
            }
            @media #{$media-mobile} {
                height: unset;

                &__text {
                    position: relative;
                    height: unset;
                    width: 100%;
                    padding: 4rem 4rem 9rem 4rem;
                    margin-bottom: -5rem;
                }
                &__video {
                    height: 53vw;
                    width: 100%;
                    margin: 0;

                    // video {
                    //     min-width: 100%;
                    // }
                }
                &.even {
                    .verticalitem {
                        &__text {
                            background: linear-gradient(to bottom, black calc(100% - 5rem), transparent 100%);
                            padding: 4rem 4rem 9rem 4rem;
                            margin-bottom: -5rem;
                        }
                        &__video {
                            margin: 0;
                        }
                    }
                }
                &:nth-child(1) {    
                    .verticalitem__text {
                        background: linear-gradient(to bottom, $orange calc(100% - 5rem), transparent 100%);
                    }
                }
                &:nth-child(3) {    
                    .verticalitem__text {
                        background: linear-gradient(to bottom, $blue calc(100% - 5rem), transparent 100%);
                    }
                }
                &:nth-child(5) {    
                    .verticalitem__text {
                        background: linear-gradient(to bottom, $green calc(100% - 5rem), transparent 100%);
                    }
                }
            }
            @media #{$media-mobile-sm} {
                &__text {
                    padding: 2rem 2rem 7rem 2rem;
                }
                &.even {
                    .verticalitem {
                        &__text {
                            padding: 2rem 2rem 7rem 2rem;
                        }
                    }
                }
            }
        }
    }
}