.defaultcard {
    max-width: 500px;
    height: fit-content!important;

    &__wrap {
        position: relative;
        margin-bottom: 1.5rem;

        &__image {
            border-radius: 1.2rem;
        }

        &__description {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            box-sizing: border-box;
            background-color: $orange;
            border-radius: 1.2rem;

            @include sym-container;
            @include richtext;
            @include richtext-small;
            color: #fff;

            @media #{$media-tablet} {
                position: relative;
                background-color: white;
                color: black;
                padding: 0 1.2rem;
            }

            @media #{$media-mobile-sm} {
                font-size: 1.7rem;
                padding: 1.5rem;
                p,li {
                    line-height: 15px;
                }
            }

            // ui
            opacity: 0;
            transition: opacity 0.5s ease-out;
            cursor: default;
            &:hover, &:focus, &:active, &.active {
                opacity: 1;
            }
            @media #{$media-tablet} {
                opacity: 1;
            }
        }

        &__mobiletitle {
            display: none;

            @media #{$media-tablet} {
                display: block;
                padding: 1.5rem 1.2rem 0.7rem 1.2rem;
            }
        }
    }

    &__title {
        margin: 1.2rem;

        @media #{$media-tablet} {
            display: none;
        }
    }
}