.template-page-day-zero {

    .s0 {
        margin-bottom: 40px;

        &__hero {
            width: 100%;
            margin-bottom: 2rem;

            img {
                width: 100%; 
            }

            // interaction
            transition: filter 0.3s ease;

            &:hover, &:active, &:focus {
                filter: grayscale(1) invert(1);
            }
        }

        &__topwrap {
            display: grid;
            grid-template-columns: auto auto;
            justify-content: space-between;
            gap: 7vw;
            
            @media #{$media-mobile} {
                grid-template-columns: auto;
                gap: 3rem;
            }
            .h2 {
                max-width: 740px;
            }
            &__details {
                text-align: right;
                font-size: 1.2em;
                max-width: 32rem;

                @media #{$media-mobile} {
                    text-align: left;
                }
            }
        }
        &__bottomwrap {
            display: grid;
            grid-template-columns: 400px auto;

            @media #{$media-tablet} {
                grid-template-columns: 300px auto;
                margin-bottom: 30px;
            }

            @media #{$media-mobile} {
                grid-template-columns: auto;
                margin-bottom: 10px;
                gap: 3rem;
            }

            &__logo {
                padding-right: 4vw;
                width: 100%;
                
                @media #{$media-tablet} {
                    padding-right: 4rem;
                }
                @media #{$media-mobile} {
                    max-width: 250px;
                }
            }
            &__text {
                font-size: 1.1em;
            }
            .logos {
                &__left {
                    margin-right: 1.5rem;
                    @media #{$media-mobile} {
                        margin-right: 0;
                        margin-bottom: 1rem;
                    }
                }
                &__title {
                    font-weight: 400;
                    color: $graytext;
                    font-size: 1.6rem;
                    @include ls;
                    width: 100%;
                    padding-bottom: 0.25em;
                    border-bottom: 1px solid #878787;
                    margin-bottom: 1.6em;
                }
                &__image {
                    height: 6rem;
                    margin-right: 4rem;
                }
            }


        }
    }

    // grid 
    .s1 {
        .defaulttext ul {
            padding-bottom: 0;
            margin-top: 0;
        }
    }

    // schedule + speakers
    .s2 {
        margin-bottom: clamp(40px,14vh,200px);
        overflow: hidden;
        
        &__topwrap {
            max-width: 900px;
            &__schedule {
                margin-bottom: 4rem;
            }

            .button {
                margin: 0rem 0 2rem 0;
                min-width: 17rem;
                font-weight: 500;
                font-size: 1.2em;
            }
        }

        .grid-speakers {
            @include container;
            grid-template-columns: repeat( auto-fit, minmax(230px, 1fr) );

            &__card {
                max-width: unset;
            }
        }

        // .swiper {
        //     @include pad-top;
        // }

        // .swiper-wrapper {
        //     height: auto;
        // }
        // .swiper-pagination-bullet-active {
        //     background: $orange;
        //     margin: 1.5rem;
        // }
        // .swiper-slide:first-of-type {
        //     @include pad-left;
        // }
    }
}